import React, { Component } from 'react'
import Card from '../../../components/Card/Card.jsx'
import AccountBox from '@material-ui/icons/AccountBox'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardIcon from '../../../components/Card/CardIcon.jsx'
import CardHeader from '../../../components/Card/CardHeader.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import Button from '../../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Spinner from 'react-spinner-material'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import Check from '@material-ui/icons/Check'
import Subscriptions from '../../../components/Subscriptions/Subscriptions.jsx'
import backend from '../../../utils/backend'
import { showSuccessMessage, showErrorMessage } from '../../../components/Alerts/Alerts'
import { BackendError } from '../../../utils/errors'
import dot from 'dot-object'

class UserDetailsPage extends Component {
  state = {
    user: {},
    viewMoreDetails: false,
    saveButtonLabel: 'Save',
    disableBackButton: false,
    disableResetPasswordButton: true,
    disableSendVerificationEmailButton: false,
    fetching: false
  }

  async componentDidMount() {
    const { id } = this.props
    if (!id) {
      const errorMessage = 'No user id selected'
      console.error(errorMessage)
      showErrorMessage(errorMessage)
    } else {
      try {
        const user = await backend.get('users', id)
        this.setState({
          user,
          disableResetPasswordButton: false
        })
      } catch (e) {
        console.error(e.message)
        return showErrorMessage(e.message)
      }
    }
  }

  handleChange = e => {
    this.setState({ 
        user: dot.str(e.target.name, e.target.value, this.state.user)
    })
  }

  toggleMoreDetails = () => {
    this.setState({
      viewMoreDetails: !this.state.viewMoreDetails
    })
  }

  sendPasswordReset = async () => {
    try {
      const resBody = await backend.sendPasswordReset(this.state.user.email)
      if (resBody.error)
        throw new Error('Error sending password reset: ' + resBody.error)
      await showSuccessMessage(
        'Password reset mail sent. Please ask user to check email!'
      )
      this.setState({ disableResetPasswordButton: true })
    } catch (e) {
      showErrorMessage(e.message)
    }
  }

  sendVerificationEmail = async () => {
    try {
      await backend.sendVerificationEmail(this.state.user.email)
      await showSuccessMessage('Verification mail sent. Please ask user to check email!')
      this.setState({ disableSendVerificationEmailButton: true })
    } catch (e) {
      showErrorMessage(e.message)
    }
  }

  handleSave = async e => {
    try {
      let result = {}
      this.setState({
        disableBackButton: true,
        fetching: true,
        saveButtonLabel: 'Saving ... '
      })
      const body = this.state.user
      body.id = body.data.user_id

      if (this.props.id) {
        result = await backend.update('users', body)
      } else {
        throw new Error('Cannot find user id')
      }

      this.setState({
        disableBackButton: false,
        fetching: false
      })

      if (result.code && result.code > 399) throw new BackendError(result)

      await showSuccessMessage('Your item has been saved!')
      this.props.goBack()
    } catch (e) {
      showErrorMessage('Error while Saving: ' + e.message)
      this.setState({
        saveButtonLabel: 'Save',
        disableBackButton: false,
        fetching: false
      })
    }
  }

  render() {
    const { classes, goBack } = this.props
    const {
      user,
      fetching,
      viewMoreDetails,
      saveButtonLabel,
      disableResetPasswordButton,
      disableBackButton,
      disableSendVerificationEmailButton
    } = this.state
    if(!user || !user.data) return <p>loading...</p>

    const userData = user.data
    return (
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <AccountBox />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>User Details</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <h5>User Details</h5>
                <TextField
                  id="nickname"
                  label="Nickname"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  value={userData.nickname}
                  disabled={true}
                />
                <TextField
                  id="email"
                  label="Email"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  value={userData.email}
                  disabled={true}
                />
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor="role-label">
                      Role
                    </InputLabel>
                    <Select
                      value={userData.app_metadata.role}
                      onChange={this.handleChange}
                      input={<Input name="data.app_metadata.role" id="role-label" />}
                      displayEmpty={false}
                      name="data.app_metadata.role"
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="" disabled>
                        <em>Role</em>
                      </MenuItem>
                      {userData.app_metadata.role === 'SuperAdmin' && (
                      <MenuItem value={'SuperAdmin'} disabled>SuperAdmin</MenuItem>)}
                      {userData.app_metadata.role === 'Admin' && (<MenuItem value={'Admin'} disabled>Admin</MenuItem>)}
                      <MenuItem value={'Viewer'}>Viewer</MenuItem>
                      <MenuItem value={'Editor'}>Editor</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <h5>
                    View more details
                    <Checkbox
                      checked={viewMoreDetails}
                      tabIndex={-1}
                      onClick={this.toggleMoreDetails}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  </h5>
                  {viewMoreDetails && (
                    <div>
                      <img src={userData.picture} alt={userData.nickname} />

                      <TextField
                        id="company"
                        label="Company"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.user_metadata.company}
                        disabled={true}
                      />
                      <TextField
                        id="email_verified"
                        label="Email verified"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.email_verified}
                        disabled={true}
                      />
                      <TextField
                        id="id"
                        label="ID"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.user_id}
                        disabled={true}
                      />
                      <TextField
                        id="created_at"
                        label="Created at (UTC)"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.created_at}
                        disabled={true}
                      />
                      <TextField
                        id="last_password_reset"
                        label="Last password reset (UTC)"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.last_password_reset || 'None'}
                        disabled={true}
                      />
                      <TextField
                        id="last_login"
                        label="Last login (UTC)"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        value={userData.last_login || 'None'}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
                
              </GridItem>
              <GridItem xs={12} md={6}>

                <Subscriptions
                  subscriptions={userData.app_metadata.subscriptions || []}
                  classes={classes}
                  handleChange={this.handleChange}
                ></Subscriptions>
              </GridItem>
            </GridContainer>
            <Grid item xs={12} style={{marginTop:"30px"}}>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="white"
                onClick={() => {
                  goBack()
                }}
                disabled={disableBackButton}
              >
                Back
              </Button>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="success"
                onClick={this.handleSave}
              >
                <Spinner
                  size={12}
                  spinnercolor={'#FFF'}
                  spinnerwidth={2}
                  visible={fetching}
                />
                {saveButtonLabel}
              </Button>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="warning"
                onClick={this.sendPasswordReset}
                disabled={disableResetPasswordButton}
              >
                Reset password
              </Button>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="info"
                onClick={this.sendVerificationEmail}
                disabled={disableSendVerificationEmailButton}
              >
                Resend confirmation email
              </Button>

            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    )
  }
}

export default UserDetailsPage
