import React, { Component } from "react"
import ReactDom from "react-dom"
import Card from "../../components/Card/Card.jsx"
import TV from "@material-ui/icons/Tv"
import CardBody from "../../components/Card/CardBody.jsx"
import CardIcon from "../../components/Card/CardIcon.jsx"
import CardHeader from "../../components/Card/CardHeader.jsx"
import GridItem from "../../components/Grid/GridItem.jsx"
import Button from "../../components/CustomButtons/Button.jsx"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import GridContainer from "../../components/Grid/GridContainer.jsx"
import backend from "../../utils/backend"
import Spinner from "react-spinner-material"
import * as constants from "./Constants"
import { BackendError } from "../../utils/errors"
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
  showConfirmationMessage,
} from "../../components/Alerts/Alerts"
import ResourceEnumDropdown from "../../components/ResourceDetails/ResourceEnumDropdown"
import ResourceEnumDropdownWithStatus from "../../components/ResourceDetails/ResourceEnumDropdownWithStatus"
import LinearProgress from "@material-ui/core/LinearProgress"
import nameGenerate from "project-name-generator"
import Swal from "sweetalert2"
import uuid from "uuid/v4"
import defaultsAdServerParams from "./defaultsAdServerParams"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"

import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import withStyles from "@material-ui/core/styles/withStyles"

import Switch from "@material-ui/core/Switch"
import Popover from "@material-ui/core/Popover"
import Typography from "@material-ui/core/Typography"
import path from "path"
import xss from "xss"

import HMACSecretOption from "./HMACSecretOption"
import ResourceSuppressHLSTagsDropdown from "../../components/ResourceDetails/ResourceSuppressHLSTagsDropdown.jsx"
import FileUploadWithChunk from "./fileUploadWithChunk"
import findDuplicatedPropertyKeys from "find-duplicated-property-keys"
import merge from "lodash/merge"

const defaultMuxingType = (channelType) => {
  if (channelType.startsWith("hls")) {
    return "ts"
  }
  if (channelType.startsWith("dash")) {
    return "fmp4"
  }
}

const xssOptions = {
  stripIgnoreTag: true,
  stripIgnoreTagBody: ["script"],
}

const defaultHmac = {
  secret: "",
  isSecretEncrypted: false,
  method: "",
  window: 0,
  cookieDomain: "",
}

let audioNormalizationDefaults = {
  integratedLoudness: -23,
  loudnessRange: 11,
  maximumTruePeakLevel: -1,
} //NEM-1089: defaults

// This validation is only used at the very end, at point of save, not on field
// change - for obvious reasons ..
const jsonError = (value) => {
  if (typeof value !== "string") {
    return ""
  }
  try {
    JSON.parse(value)
    return "" // to make the rest of the code easier, clearer
  } catch (err) {
    return constants.ERROR_INVALID_JSON
  }
}

const checks = {
  valueInRange: (value, min, max) => {
    if (value < min || value > max) {
      return "value must be between " + min + " and " + max
    }
    return ""
  },
  hasValue: (value) => (value ? "" : constants.ERROR_EMPTY),
  isURL: (value) => {
    try {
      new URL(value)
      return ""
    } catch (_) {
      return constants.ERROR_URL_NOT_VALID
    }
  },
}

const textAbout3Jobs =
  "No media found - 3 jobs to create it already failed. Please check if there is an issue with the source and archive the existing jobs to be able to retry."

// on Save field input validations
const validationList = {
  name: [checks.hasValue],
  sourceManifestUrl: [checks.hasValue, checks.isURL],
  timeShiftWindow: [
    (value) => {
      return checks.valueInRange(value, 0, 3600)
    },
  ], // max 60 mins
}

const channelUrlText = {
  vod: `Please provide an example video from your playlist (HLS or DASH).
  We will analyse it to figure out the correct content conditioning profile for you.
  (If you do not have an example yet you can specify the profile below or choose one of our presets)`,
  live: `Please provide the URL to your live stream. We will analyse it to
  figure out the correct content conditioning profile and take it as an input
  source to insert personalised ads. (You will have to opportunity to fine tune your profile below)`,
}

const streamTypes = [
  { id: "hls", name: "hls" },
  { id: "dash", name: "dash" },
]

const roundButtonStyle = {
  borderRadius: "25px",
  width: "25px",
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingRight: "16px",
  paddingBottom: "8px",
  marginLeft: "10px",
}

const defaultStitcherSettings = {
  shouldUseRelativeSegmentUrl: false,
  numberOfSegmentsToReplayAfterMidroll: 3,
  includeDashEvents: false,
  shouldFilterHlsByBandwidth: false,
  bandwidthThreshold: 650000,
  useScte35: false,
  replaceInsteadOfInsert: false,
  provideVastURL: false,
  removeTrackingTemplate: false,
}

const defaultPreRollSettings = {
  adSource: {
    system: "spotx",
    url: "",
    params: "",
  },
  maxDuration: 30,
  maxCount: 1,
}

const CURRENT_CHANNEL_PROFILE = "currentChannel.profile"
const CURRENT_CHANNEL_ADSOURCE_PARAMS = "currentChannel.adSource.params"
const CURRENT_CHANNEL_PREROLL_ADSOURCE_PARAMS = "currentChannel.preRoll.adSource.params"

class ChannelDetailsPage extends Component {
  constructor() {
    super()
    this.onUploadSuccessful = this.onUploadSuccessful.bind(this)
  }

  _isMounted = false

  state = {
    JSONFields: [
      { path: CURRENT_CHANNEL_PROFILE, isProper: undefined },
      { path: CURRENT_CHANNEL_ADSOURCE_PARAMS, isProper: undefined },
      { path: CURRENT_CHANNEL_PREROLL_ADSOURCE_PARAMS, isProper: undefined },
    ],
    // **NOTE:  currentChannel object MUST conform to the schema spec (found in the backend channels.service.schema)
    // this means structure, all field names etc.
    currentChannel: {
      profile: {},
      id: uuid(),
      type: "dash-vod",
      sourceManifestUrl: "",
      name: "",
      state: "disabled",
      outputUri: "",
      apiKey: "",
      adSource: {
        url: "",
        system: "spotx",
        params: "", // will be JSONed to an object
      },
      adSlateManifestUrl: "",
      stitcher: defaultStitcherSettings,
      preRoll: defaultPreRollSettings,
      enablePreRoll: false,
      timeShiftWindow: 0,
      http: false,
      enableServerSideBeacons: false,
      isMultiPeriod: false,
      compatibilityHlsMedia: false,
      minimumSourceUpdatePeriod: "",
      setScanningFrequency: false,
      dynamicConfiguration: false,
      fallbackSourceTags: false,
      passThroughCaidSegmentation: false,
      cohortsEnabled: false,
      xApiKey: "",
      trueSourceUrl: "",
      cacheList: "",
      prefetchDuration: 0,
      cacheTime: 0,
      contentLocationReplacement: undefined,
      adLocationReplacement: undefined,
      segmentationConfiguration: undefined,
      suggestedPresentationDelay: 0,
      hlsSuppressTags: [],
      preservePlaylistFilename: false,
      dvrWindow: 0,
      startAtDvrWindow: true,
      includeMediaTracks: {
        iFrames: false,
        subtitles: false,
        closedCaptions: false,
      },
    },
    newProfileInfo: {
      analyzed: false,
      data: {},
    },
    //ui
    saveButtonLabel: "Save",
    disableSaveButton: false,
    disableBackButton: false,
    fetching: false,
    analysing: false,
    profileOpen: false,
    encodingProgress: false,
    isAnalyzed: false,

    //validation errors
    errors: [],

    fileName: "",
    file: {},

    popovers: {
      integratedLoudness: {
        anchorEl: null,
        text: `Set the targeted integrated loudness value. Range is from '-70.0' to '-5.0'. Default value is '${audioNormalizationDefaults.integratedLoudness}'. Value is measured in LUFS (Loudness Units, referenced to Full Scale)`,
      },
      loudnessRange: {
        anchorEl: null,
        text: `Set the targeted loudness range target. Range is from '1.0' to '20.0'. Default value is '${audioNormalizationDefaults.loudnessRange}'. Loudness range measures the variation of loudness on a macroscopic time-scale in units of LU (Loudness Units). For programmes shorter than 1 minute, the use of the measure Loudness Range is not recommended due to too few data points (Loudness Range is based on the Short-term-Loudness values (3-seconds-window)).`,
      },
      maximumTruePeakLevel: {
        anchorEl: null,
        text: `Set maximum true peak. Range is from '-9.0' to '0.0'. Default value is '${audioNormalizationDefaults.maximumTruePeakLevel}'. Values are measured in dBTP (db True Peak)`,
      },
      minimumSourceUpdatePeriod: {
        anchorEl: null,
        text: `By setting a specific scanning frequency in seconds, serverside.ai will request the source playlist accordingly. The default value is set to 1/2 of the source target duration.`,
      },
      beaconImpression: { anchorEl: null, text: `Ad inserted` },
      beaconStart: { anchorEl: null, text: `Ad started` },
      beaconFirstQuartile: { anchorEl: null, text: `25% viewed` },
      beaconMidpoint: { anchorEl: null, text: `50% viewed` },
      beaconThirdQuartile: { anchorEl: null, text: `75% viewed` },
      beaconComplete: { anchorEl: null, text: `100% viewed` },
      beaconClick: { anchorEl: null, text: `Include serverside clickTrackingURL` },

      // lockProfile: { anchorEl: null, text: `Lock profile to prevent changes` },
      httpProxyUrl: { anchorEl: null, text: `Custom http proxy for stitcher` },
      shouldUseRelativeSegmentUrl: {
        anchorEl: null,
        text: `Use relative segment URL means not to include base address in segments url`,
      },
      numberOfSegmentsToReplayAfterMidroll: { anchorEl: null, text: "Number of segments to replay after midroll" },
      includeDashEvents: { anchorEl: null, text: "Include DASH events" },
      shouldFilterHlsByBandwidth: { anchorEl: null, text: "Filter HLS by bandwidth" },
      bandwidthThreshold: { anchorEl: null, text: "Maximum HLS bandwidth" },
      preRollMaxDuration: { anchorEl: null, text: "Maximum preroll duration in seconds" },
      preRollMaxCount: { anchorEl: null, text: "Maximum number of ads inserted into the preroll ad-pod" },
      enablePreRoll: { anchorEl: null, text: "If activated one or more ads are played before the stream starts" },
      timeShiftWindow: {
        anchorEl: null,
        text: "Set the live channel time-shift window in seconds. (max 3600 = 1 hour)",
      },
      dvrWindow: {
        anchorEl: null,
        text: "Configuration of the maximum playlist size in seconds. If 0 is selected, then the source playlist size will be used.",
      },
      startAtDvrWindow: {
        anchorEl: null,
        text: "If disabled [DEFAULT], then the playlist size will be using the source playlist size. If enabled, then the playlist size will grow until the source size of configured size (see Playlist size).",
      },
      "segmentationConfiguration.segmentationTypeIdsStart": {
        anchorEl: null,
        text: "[time-signal] Set of segmentation types signaling start of replacement opportunity",
      },
      "segmentationConfiguration.segmentationTypeIdsEnd": {
        anchorEl: null,
        text: "[time-signal] Set of segmentation types signaling end of replacement opportunity",
      },
      "segmentationConfiguration.scte35IsHexNotBase64": {
        anchorEl: null,
        text: "SCTE35 commands are hexadecimal [true] or base64 [false] encoded",
      },
      "segmentationConfiguration.defaultDuration": {
        anchorEl: null,
        text: "Fallback when no cue-out duration provided",
      },
      "segmentationConfiguration.durationFromScte35OverManifest": {
        anchorEl: null,
        text: "SCTE35 duration overrules HLS attribute DURATION",
      },
      "segmentationConfiguration.fallbackSourceTags": { anchorEl: null, text: "Fallback when ad not inserted" },
      useScte35: {
        anchorEl: null,
        text: "If active, the Ad-Markers will be taken from SCTE-35 markers in the manifest file",
      },
      replaceInsteadOfInsert: {
        anchorEl: null,
        text: "If the personalized ads should replace an existing ad-break or be inserted in a file that has no original ads",
      },
      provideVastURL: { anchorEl: null, text: "Providing the corresponding VAST.xml URL within the stitcher response" },
      hmac: {
        anchorEl: null,
        text: "HMAC: An authentication method that verifies requests via an HMAC token and secures TS segments with a newly created token. This implementation is based on the Akamai HMAC implementation. Details can be found in our documentation: https://nowtilus.gitbook.io/serverside-ai/.",
      },
      hmacSecret: { anchorEl: null, text: "Shared secret." },
      hmacMethod: { anchorEl: null, text: "The method used to validate and generate HMAC token." },
      hmacWindow: { anchorEl: null, text: "Lifetime of the token until it expires. (In seconds)" },
      hmacCookieDomain: { abchorEl: null, text: "Define the domain for the HMAC cookie" },
      hlsIndependentMediaSequences: {
        anchorEl: null,
        text: "Independent Media Sequences: Support independent media sequences. (This will improve compatibility but can lead to increased load on the system)",
      },
      hlsLegacyMediaSequenceHandling: {
        anchorEl: null,
        text: "Legacy Media Sequence Handling: Non-Legacy mode will handle not inline between playlists more gracefully. (This will improve compatibility but can lead to increased load on the system)",
      },
      generateTitleIdsUsingURL: {
        anchorEl: null,
        text: "Ad conditioning will ignore incoming ad ID and use source URL to generate it",
      },
      suppressHLSTags: {
        anchorEl: null,
        text: "Not every HLS player supports all HLS tags. In this section you can select HLS tags that should not be included in the manifest to increase the compatibility.",
      },
      preservePlaylistFilename: {
        abchorEl: null,
        text: "By enabling the preserve playlist name, the serverside.ai output playlist will follow the source.",
      },
    },
  }

  setJSONEntryState = (path, isProper) => {
    const { JSONFields } = this.state
    const entry = JSONFields.find((entry) => entry.path === path)
    entry.isProper = isProper
    this._setState({ JSONFields })
  }

  getJSONError = (path) => {
    const { JSONFields } = this.state
    const entry = JSONFields.find((entry) => entry.path === path)
    if (entry?.isProper === false) {
      return "Invalid JSON"
    }
    return ""
  }

  getLessThan15ByteError = () => {
    if (
      this.state.currentChannel?.adSource?.params?.functions?.hashValueWithSalt &&
      this.state.currentChannel.adSource.params?.functions?.hashValueWithSalt?.[1]?.length < 15
    ) {
      return "Value should be at least 15 bytes long."
    }
    return ""
  }

  getDraftName = (path) => {
    return path.replace(/\./g, "_") + "__draft"
  }
  getValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
  }
  setValue = (obj, path, value) => {
    const keys = path.split(".")
    const lastKey = keys.pop()
    const lastObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj)
    lastObj[lastKey] = value
  }
  updateState(path, value) {
    this._isMounted &&
      this.setState((prevState) => {
        const newState = { ...prevState }
        this.setValue(newState, path, value)
        return newState
      })
  }
  handleJSONFieldChange = (path) => async (event) => {
    let newValue = event?.target?.value
    const input = event.target
    const start = input.selectionStart
    const end = input.selectionEnd

    const placeholder = `"***************"`
    const currentHashValue = this.state.currentChannel?.adSource?.params?.functions?.hashValueWithSalt?.[1]

    if (currentHashValue && newValue.includes(placeholder)) {
      newValue = newValue.replace(placeholder, `"${currentHashValue}"`)
    }

    this._setState(
      {
        [this.getDraftName(path)]: newValue,
      },
      () => {
        input.setSelectionRange(start, end)
      },
    )

    try {
      await this.parseJSON({}, path, newValue, false)
      this.setJSONEntryState(path, true)
    } catch (ex) {
      console.log("ex", ex)
    }
  }

  parseJSON = async (parsedObjects, path, newValue, errorMessageIsNeeded = true) =>
    new Promise(async (resolve, reject) => {
      try {
        if (!newValue) {
          newValue = this.getDraftValue(path)
        }
        this.setJSONEntryState(path, null)
        newValue = await this.checkJson(newValue, `Invalid JSON in ${path}`)
        const parsedValue = JSON.parse(newValue)
        this.updateState(path, parsedValue)
        this.setValue(parsedObjects, path, parsedValue)
        resolve()
      } catch (error) {
        this.setJSONEntryState(path, false)
        if (errorMessageIsNeeded) {
          await showConfirmationMessage(
            () => {
              const originalValue = this.getValue(this.state, path)
              this.updateState(this.getDraftName(path), JSON.stringify(originalValue, null, 2))
              this.setValue(parsedObjects, path, originalValue)
              this.setJSONEntryState(path, null)
              reject()
            },
            {
              title: `${path} can't be parsed`,
              text: "You can fix it manually or reset it to the latest valid version",
              confirmButtonText: "Reset to the latest valid version",
              cancelButtonText: "Fix errors manually",
              onCancel: () => {
                reject()
              },
            },
          )
        }
      }
    })

  parseJSONs = async (parsedObjects) => {
    await Promise.all(this.state.JSONFields.map((entry) => this.parseJSON(parsedObjects, entry.path)))
  }

  getDraftValue = (path, state) => {
    let draftValue = this.getValue(state || this.state, this.getDraftName(path))
    if (typeof draftValue === "string") {
      return draftValue
    }

    let obj = JSON.stringify(this.getValue(state || this.state, path), null, 2)

    let jsonObj = JSON.parse(obj)

    let hashValues = jsonObj?.functions?.hashValueWithSalt

    if (hashValues) {
      for (let i = 0; i < hashValues?.length - 1; i++) {
        if (/^\{\{.*\}\}$/.test(hashValues[i])) {
          if (i + 1 < hashValues.length) {
            hashValues[i + 1] = "***************"
          }
        }
      }
    }

    return JSON.stringify(jsonObj, null, 2)
  }

  _setState = (...args) => {
    this._isMounted && this.setState(...args)
  }

  // Recursive function to log nested properties
  logNestedProperties = (obj, path = "", level = 0) => {
    if (level > 3) {
      return
    }
    if (typeof obj !== "object" || obj === null) {
      return
    }

    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      const currentPath = path ? `${path}.${key}` : key
      if (value === "[object Object]") {
        console.warn(`State property ${currentPath} is being set to [object Object]`)
      } else if (typeof value === "object") {
        if (value !== null) {
          // console.warn(`State property ${currentPath} is being set to an object:`, value);
          this.logNestedProperties(value, currentPath, level + 1) // Recurse into nested objects
        }
      }
    })
  }

  toggleProfile = () => {
    this._setState({
      profileOpen: !this.state.profileOpen,
    })
  }

  toggleContentLocation = () => {
    this._setState({
      contentLocationOpen: !this.state.contentLocationOpen,
    })
  }

  toggleAdLocation = () => {
    this._setState({
      adLocationOpen: !this.state.adLocationOpen,
    })
  }

  toggleSegmentationConfiguration = () => {
    this._setState({
      segmentationConfigurationOpen: !this.state.segmentationConfigurationOpen,
    })
  }

  modifyProfile = async (newProfileObject) => {
    try {
      await this.findDuplicatesInProfile()
      const profile = { ...this.state.currentChannel.profile, ...newProfileObject }
      let currentChannel = {
        ...this.state.currentChannel,
        profile,
      }
      this._setState({ currentChannel })
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  modifyCurrentChannel = (newChannelObject, setAdSlate = true) => {
    if (newChannelObject.channelType && this.state.currentChannel.type) {
      newChannelObject.type = `${this.state.currentChannel.type.split("-")[0]}-${newChannelObject.channelType}`
    }
    if (newChannelObject.internalBeacons && newChannelObject.internalBeacons.baseUrl) {
      newChannelObject.internalBeacons.baseUrl = newChannelObject.http
        ? newChannelObject.internalBeacons.baseUrl.replace(/^https:\/\//i, "http://")
        : newChannelObject.internalBeacons.baseUrl.replace(/^http:\/\//i, "https://")
    }
    let outputUri
    if (this.state.currentChannel.outputUri) {
      outputUri = newChannelObject.http
        ? this.state.currentChannel.outputUri.replace(/^https:\/\//i, "http://")
        : this.state.currentChannel.outputUri.replace(/^http:\/\//i, "https://")

      if (!this.state.currentChannel.dynamicConfiguration && newChannelObject.dynamicConfiguration) {
        outputUri = outputUri.replace("dev.", "dev-mars.").replace("qa.", "qa-venus.")
        let insert = "://"
        let position = outputUri.indexOf(insert) + insert.length
        outputUri = outputUri.slice(0, position) + "poseidon." + outputUri.slice(position)
      }

      if (this.state.currentChannel.dynamicConfiguration && !newChannelObject.dynamicConfiguration) {
        outputUri = outputUri.replace("dev-mars.", "dev.").replace("qa-venus.", "qa.").replace("poseidon.", "")
      }
    }
    if (!this.state.currentChannel.setScanningFrequency) {
      newChannelObject.minimumSourceUpdatePeriod = ""
    }
    let currentChannel = {
      ...this.state.currentChannel,
      ...newChannelObject,
      contentLocationReplacement: this.state.currentChannel.contentLocationReplacement || {},
      adLocationReplacement: this.state.currentChannel.adLocationReplacement || {},
      outputUri,
    }
    this._setState({ currentChannel })
    setAdSlate && this.setAdSlate()
  }

  handlePopoverClick = (key) => {
    return (event) => {
      this.closeAllPopovers()
      this._setState({
        popovers: { ...this.state.popovers, [key]: { ...this.state.popovers[key], anchorEl: event.currentTarget } },
      })
    }
  }

  handlePopoverClose = (key) => {
    return () => {
      this._setState({ popovers: { ...this.state.popovers, [key]: { ...this.state.popovers[key], anchorEl: null } } })
    }
  }

  closeAllPopovers = () => {
    if (!this._isMounted) return

    const popovers = this.state.popovers

    for (var prop in popovers) {
      if (Object.prototype.hasOwnProperty.call(popovers, prop)) {
        popovers[prop] = { ...popovers[prop], anchorEl: null }
      }
    }

    this._setState({ popovers })
  }

  handleKeyDown = (event) => {
    // Check if the user is pressing Ctrl + S (Windows/Linux) or Cmd + S (Mac)
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      event.preventDefault() // Prevent the default browser save action
      // Call your save function here
      this.handleSave()
    }
    if ((event.ctrlKey || event.metaKey) && event.key === "h") {
      event.preventDefault() // Prevent the default browser whatever action
      this.makeHbbTVChannel()
    }
  }

  makeHbbTVChannel = () => {
    this.modifyCurrentChannel({
      sourceManifestUrl: "http://some.url",
      adSlateManifestUrl: "http://some.url",
      profile: {},
      type: "dash-live",
      http: true,
      adSource: {
        system: "proxy",
        url: "http://localhost:3102",
        params: {},
      },
    })
    this._setState({ profileOpen: true })
  }

  async componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown)
    window.makeHbbTVChannel = this.makeHbbTVChannel
    this._isMounted = true
    const { id } = this.props
    let currentChannel = {}
    let schema, systems, systemSchema, ssai, baseUrl, encoderSystem
    try {
      ;[schema, systems, systemSchema] = await Promise.all([
        backend.modelSchema("channels"), // modelSchema ensures we get the full schema depth, including e.g. enum for adServer
        backend.list({ resource: "systems" }),
        backend.schema("systems"),
      ])
      ssai = systems.find((s) => s.category === "ssai")
      encoderSystem = systems.find((s) => s.category === "encoder")
      baseUrl = (ssai && ssai.settings.beaconsBaseUrl) || systemSchema.beaconsBaseUrl
    } catch (ex) {
      console.error("Cannot load data")
    }
    if (!id) {
      currentChannel = {
        ...this.state.currentChannel,
        name: this.generateName(),
        internalBeacons: {
          baseUrl,
          events: ["impression", "start", "firstQuartile", "midpoint", "thirdQuartile", "complete"],
        },
      }
      currentChannel.adSource.params = this.loadDefaultAdServerParams(currentChannel.adSource)
      currentChannel.preRoll.adSource.params = this.loadDefaultAdServerParams(currentChannel.preRoll.adSource)
    } else {
      try {
        currentChannel = await backend.get(constants.CHANNELS, id)
        currentChannel.id = id
        currentChannel.outputUri = currentChannel.outputUri || ""
        if (currentChannel.adSource.params && currentChannel.adSource.params.metadata) {
          delete currentChannel.adSource.params.metadata
        }

        // handle preRoll
        currentChannel.preRoll = currentChannel.preRoll || defaultPreRollSettings
        currentChannel.preRoll.adSource = currentChannel.preRoll.adSource || {}
        currentChannel.stitcher = currentChannel.stitcher || defaultStitcherSettings
        // Default? If not present, is added in channels-service anyway ...
        // currentChannel.timeShiftWindow =

        this.dealWithNullableObjects(currentChannel)

        this._setState({
          currentChannel: {
            ...currentChannel,
            includeMediaTracks: currentChannel.includeMediaTracks || {
              iFrames: false,
              subtitles: false,
              closedCaptions: false,
            },
          },
          unchangedProfile: currentChannel.profile,
          encoderSystem,
          baseUrl,
          schema,
          isAnalyzed: true,
        })

        this.setAdSlate()
      } catch (e) {
        if (e.response && e.response.status) {
          this._setState({ status: e.response.status })
        } else {
          return showErrorMessage("Error while Loading. " + e.message)
        }
      }
    }

    if (this._isMounted && !id) {
      const newCurrentChannel = { ...this.state.currentChannel, ...currentChannel }
      this.dealWithNullableObjects(newCurrentChannel)
      this._setState({
        currentChannel: newCurrentChannel,
        schema,
        baseUrl,
        unchangedProfile: undefined,
        encoderSystem,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  assetUrlIdGetter(url = "") {
    const regex = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g
    const result = url.toLowerCase().match(regex)
    return result && result[0]
  }

  setAdSlate = async () => {
    if (!this._isMounted) return
    try {
      const targetAdSlateAssetId = this.assetUrlIdGetter(this.state.currentChannel.adSlateManifestUrl)
      const adSlates = await backend.list({ resource: "titles", type: "ad-slate", limit: -1 })
      const currentAdSlateId = (await backend.list({ resource: "medias", search: targetAdSlateAssetId })).reduce(
        (r, item) => {
          if (!r) return item.titleId
          return r
        },
        null,
      )

      //for drop-down:
      const adSlatesById = {}
      for (let i = 0; i < adSlates.length; ++i) {
        adSlatesById[adSlates[i].id] = adSlates[i].name
      }

      this._setState({ adSlatesById, adSlates, currentAdSlateId })
    } catch (ex) {
      console.log(ex)
      return showErrorMessage("Cannot load ad-slates from server. Please try again later or contact support.")
    }
  }

  generateName = () => nameGenerate({ words: 2, alliterative: true }).spaced + " channel"

  setErrors = (errors) => {
    this._setState({ errors })
  }

  resetErrors = () => {
    this._setState({ errors: [] })
  }

  getErrors = (field) => {
    return this.state.errors
      .filter((error) => error.key === field)
      .map((error) => error.error)
      .join(" ")
  }

  validateField = (key, value, validations) => {
    const errors = []

    validations.forEach((validation) => {
      const error = validation(value)
      if (error) {
        errors.push({ key, error })
      }
    })
    return errors
  }

  // segmentationConfiguration
  checkStartEndWithSanitise = ({ channel, segmentationTypeIdsFieldName, errors }) => {
    let segmentationTypeIdsErr
    let segmentationTypeIdsField = channel.segmentationConfiguration[segmentationTypeIdsFieldName]

    if (!Array.isArray(segmentationTypeIdsField)) {
      segmentationTypeIdsField = segmentationTypeIdsField || "[]"
      if (!segmentationTypeIdsField.startsWith("[")) {
        segmentationTypeIdsField = "[" + segmentationTypeIdsField
      }
      if (!segmentationTypeIdsField.endsWith("]")) {
        segmentationTypeIdsField = segmentationTypeIdsField + "]"
      }

      segmentationTypeIdsErr =
        segmentationTypeIdsField && segmentationTypeIdsField !== "" && jsonError(segmentationTypeIdsField)

      if (segmentationTypeIdsErr) {
        errors.push({
          key: `segmentationConfiguration.${segmentationTypeIdsFieldName}`,
          error: segmentationTypeIdsErr,
        })
      } else {
        segmentationTypeIdsField = JSON.parse(segmentationTypeIdsField)
      }
    }

    if (segmentationTypeIdsField.length === 0) {
      errors.push({
        key: `segmentationConfiguration.${segmentationTypeIdsFieldName}`,
        error: `${segmentationTypeIdsFieldName} cannot be empty`,
      })
    }
  }

  updateDraftValueForProfile = (path, value) => {
    this._setState({
      [this.getDraftName(path)]: {
        ...this.state[this.getDraftName(path)],
        value,
      },
    })
    this.modifyProfile(value)
  }

  validate = (channel) => {
    let errors = []

    // validations for individual fields
    Object.keys(channel).forEach((key) => {
      const validations = validationList[key]
      if (validations) {
        const value = channel[key]
        errors = errors.concat(this.validateField(key, value, validations))
      }
    })

    // whole object validations or special cases
    let adSourceParamsErr =
      channel.adSource.params && channel.adSource.params !== "" && jsonError(channel.adSource.params)
    if (adSourceParamsErr) {
      errors.push({ key: "adSource.params", error: adSourceParamsErr })
    }

    return errors
  }

  clearSegmentationConfiguration = () => {
    this._setState({
      currentChannel: { ...this.state.currentChannel, segmentationConfiguration: {} },
    })
  }

  handleSave = async () => {
    let errorMessageIsNeeded = false
    try {
      const parsedObjects = {}
      await this.parseJSONs(parsedObjects)
      errorMessageIsNeeded = true
      await this.findDuplicatesInProfile(parsedObjects)
      if (
        !this.state.unchangedProfile ||
        JSON.stringify(this.state.currentChannel.profile) === JSON.stringify(this.state.unchangedProfile)
      ) {
        return this.saveChannel(parsedObjects)
      } else {
        showConfirmationMessage(() => this.saveChannel(), {
          title: "Channel profile has changed",
          text: `Are you sure you want to save the channel with changed profile? It may cause reencodings of some medias.`,
          confirmButtonText: "Yes",
        })
      }
    } catch (error) {
      if (errorMessageIsNeeded) {
        showErrorMessage(error?.message)
      }
    }
  }

  findDuplicatesInProfile = (state) =>
    new Promise(async (resolve, reject) => {
      const profileString = JSON.stringify(this.getValue(state || this.state, CURRENT_CHANNEL_PROFILE))
      const duplicates = findDuplicatedPropertyKeys(profileString)
      if (duplicates.length > 0) {
        reject(new Error("Profile contains duplicates"))
      }
      resolve()
    })

  saveChannel = async (parsedObjects) => {
    const newState = merge(this.state, parsedObjects)
    this.resetErrors()
    if (!this.state.currentChannel.setScanningFrequency) {
      const currentChannel = newState.currentChannel
      delete currentChannel.minimumSourceUpdatePeriod
      this._setState(currentChannel)
    }
    const errors = this.validate(newState.currentChannel)
    this.setErrors(errors)

    if (this.getLessThan15ByteError() !== "") {
      errors.push({ key: "adSource.params.functions.hashValueWithSalt[1]", error: this.getLessThan15ByteError() })
    }
    try {
      if (errors.length > 0) {
        throw new Error("Invalid fields: " + errors.map((e) => `${e.key}: ${e.error}`).join(", "))
      }
      let result = {}
      this._setState({
        disableSaveButton: true,
        disableBackButton: true,
        fetching: true,
        saveButtonLabel: "Saving ... ",
      })

      let channel = newState.currentChannel
      if (channel.internalBeacons && JSON.stringify(channel.internalBeacons) !== "{}") {
        channel.internalBeacons = {
          ...channel.internalBeacons,
          baseUrl: this.state.baseUrl,
        }
      }

      let channelCopy = JSON.parse(JSON.stringify(channel))
      if (newState.currentChannel.preRoll?.adSource?.params) {
        channelCopy.preRoll = channel.preRoll || {}
        channelCopy.preRoll.adSource = channel.preRoll.adSource || {}
      }

      // make sure we don't try to send a stitcher for a live channel
      if (this.getChannelTypeShort(channelCopy) === "live") {
        delete channelCopy.stitcher
      }

      if (!channelCopy.enablePreRoll) delete channelCopy.preRoll
      if (!channelCopy.timeShiftWindow || channelCopy.timeShiftWindow === "") channelCopy.timeShiftWindow = 0

      if (!channelCopy.authentication) channelCopy.authentication = {}

      if (!Number.isInteger(channelCopy.dvrWindow)) {
        channelCopy.dvrWindow = 0
      }

      // if (this.state.tempHashValueWithSalt === null || this.state.tempHashValueWithSalt === "***************") {
      //   // delete channelCopy.adSource.params.functions.hashValueWithSalt[1]
      //   // remove the hashValueWithSalt[1]
      //   channelCopy.adSource.params.functions.hashValueWithSalt =
      //     channelCopy.adSource.params.functions.hashValueWithSalt.filter((_, i) => i !== 1)
      // }

      // console.log("channelCopy", channelCopy.adSource.params.functions.hashValueWithSalt)

      if (this.props.id) {
        result = await backend.update(constants.CHANNELS, channelCopy)
      } else {
        result = await backend.create(constants.CHANNELS, channelCopy)
      }

      this._setState({
        disableSaveButton: true,
        disableBackButton: false,
        fetching: false,
      })

      this._setState({
        unchangedProfile: channelCopy.profile,
      })

      // make sure we give the full error information
      // **TODO: make this a generic thing
      if (result.code && result.code > 399) throw new BackendError(result)

      this.resetErrors()
      await showSuccessMessage("Your item has been saved")
      this.props.toggleDisplay(this.props.id ? this.props.id : "")
      this._setState({
        disableSaveButton: false,
        disableBackButton: false,
        fetching: false,
        saveButtonLabel: "Save",
        outputUri: result.outputUri, // this can change, for instance if http toggle is set or unset
      })
    } catch (err) {
      let message = "Error while Saving: " + err.message
      if (err?.message?.includes && err.message.includes("SSAI API") && err.message.includes("unauthorized")) {
        message =
          "SSAI System endpoint is accessible, but credentials were not accepted, check your config in Ad Insertion / Systems / SSAI-Cluster (for SuperAdmin group only)"
      }
      if (err?.message?.includes && err.message.includes("SSAI API") && err.message.includes("ENOTFOUND")) {
        message =
          "SSAI System endpoint is not accessible, check your config in Ad Insertion / Systems / SSAI-Cluster (for SuperAdmin group only)"
      }
      if (
        err?.message?.includes &&
        err.message.includes("SSAI API") &&
        err.message.includes("No server is available to handle this request")
      ) {
        message =
          "SSAI System endpoint is not accesible, most likely ssai-channel-api service is down (cluster's ingress returned 'No server is available to handle this request'). Contact OPs team or check your ssai settings in Ad Insertion / Systems / SSAI-Cluster (for SuperAdmin group only)"
      }
      showErrorMessage(message)
      this._setState({
        saveButtonLabel: "Save",
        disableSaveButton: false,
        disableBackButton: false,
        fetching: false,
      })
    }
  }

  onUploadSuccessful(data) {
    const jobId = data.job.id
    const sourceMedia = data.sourceMedia
    const adSlate = data.title

    this.watchEncoding({
      jobId,
      currentChannel: this.state.currentChannel,
      adSlate,
      sourceMedia,
    })

    Swal.fire({
      type: "success",
      title: "Upload Successful",
      text: "The file was uploaded successfully. A process is running in the backround to create the packages matching your channel. This may take a few minutes.",
      confirmButtonText: "OK",
    })
  }

  onUploadFailed(why) {
    let msg = "Please try again or contact support."
    if (why instanceof ProgressEvent && why.currentTarget instanceof XMLHttpRequest) {
      try {
        const response = JSON.parse(why.currentTarget.response)
        msg = response.message
      } catch (ex) {
        console.error("Cannot parse error", why, ex)
      }
    }
    Swal.fire({
      type: "warning",
      title: "Upload Error",
      text: "There was an error on uploading your file. " + msg,
      confirmButtonText: "OK",
    })
  }

  dealWithNullableObjects = (channel) => {
    if (!channel.internalBeacons) {
      channel.internalBeacons = {}
    }
    if (!channel.contentLocationReplacement) {
      channel.contentLocationReplacement = {}
    }
    if (!channel.adLocationReplacement) {
      channel.adLocationReplacement = {}
    }
    if (!channel.segmentationConfiguration) {
      channel.segmentationConfiguration = {}
    }
  }

  handleChange = (event) => {
    let currentChannel = this.state.currentChannel
    // deal with one level of object properties
    // e.g. adSource.url
    // I have temporaily forgotten any neater way to do this !
    const id = event.target.id || event.target.name // (some base controls only return name, value in target)
    let params = id.split(".")

    params.reduce((aggregate, current, i) => {
      if (i === params.length - 1) aggregate[current] = event.target.value
      return aggregate[current]
    }, currentChannel)

    // check if value matches mix & max
    if (event.target.name === "minimumSourceUpdatePeriod") {
      const minMaxValue = {
        min: 0,
        max: 20,
      }

      if (event.target.value && !isNaN(event.target.value)) {
        currentChannel.minimumSourceUpdatePeriod = Math.max(
          Number(minMaxValue.min),
          Math.min(Number(minMaxValue.max), Math.ceil(Number(event.target.value))),
        )
      } else {
        currentChannel.minimumSourceUpdatePeriod = ""
        showErrorMessage("Value should be number between 0 and 20")
      }
    }

    // recalculate the ad-server-params
    if (event.target.name === "adSource.system") {
      currentChannel.adSource.params = this.loadDefaultAdServerParams(currentChannel.adSource)
    }
    // recalculate the ad-server-params
    if (event.target.name === "preRoll.adSource.system") {
      currentChannel.preRoll.adSource.params = this.loadDefaultAdServerParams(currentChannel.preRoll.adSource)
    }

    this.dealWithNullableObjects(currentChannel)
    this._setState({ currentChannel })
  }

  loadDefaultAdServerParams(adSource) {
    if (defaultsAdServerParams[adSource.system]) {
      const defaultParams = defaultsAdServerParams[adSource.system]
      return defaultParams
    } else return {}
  }

  handleAdSlateSelect = async (event) => {
    const currentChannel = this.state.currentChannel
    const adSlate = this.state.adSlates && this.state.adSlates.find((ads) => ads.id === event.target.value)
    if (!adSlate) return

    if (!currentChannel.profile) {
      await Swal.fire({
        type: "warning",
        title: "No Profile",
        text: "A profile has to be added to the channel before selecting an ad slate",
        confirmButtonText: "OK",
      })
      return
    }
    let sourceMedias
    try {
      sourceMedias = await backend.list({ resource: "medias", titleId: adSlate.id, type: "source", limit: 100 })
    } catch (ex) {
      console.error("backend.list method failed")
      return
    }
    const sourceMedia = sourceMedias[0]
    try {
      if (!currentChannel.type) {
        Swal.fire({
          type: "error",
          title: "Cannot create ad slate",
          text: "Please select channel type first (in profile section)",
          confirmButtonText: "OK",
        })
        return
      }
      const resultingMedia = await backend.putChannelAsset({
        titleId: adSlate.id,
        channelId: currentChannel.id,
        channelType: currentChannel.type,
        profile: currentChannel.profile,
        mediaUrl: sourceMedia.url,
        name: adSlate.name,
        http: currentChannel.http || false,
        workflowParams: {
          externalResourceId: sourceMedia.externalResourceId,
          externalResourceType: sourceMedia.externalResourceType,
          createdBySystemId: sourceMedia.createdBySystemId,
        },
      })

      currentChannel.adSlateManifestUrl = resultingMedia.url
    } catch (e) {
      if (
        (e.message.includes("Not Found") || e.message.includes("ERR_NOT_FOUND")) &&
        !e.message.includes("3 jobs to create it already failed")
      ) {
        try {
          currentChannel.adSlateManifestUrl = ""
          // Extremely ugly - not sure if there is a better way to parse the response
          // (here we remove all non-json texts from message)
          let parsableMessage = e.message.substr(e.message.indexOf("{"), e.message.length - e.message.indexOf("{"))
          parsableMessage = parsableMessage.substr(0, parsableMessage.lastIndexOf("}") + 1)

          const response = JSON.parse(parsableMessage)

          let jobId
          if (response.data && response.data.job && response.data.job.id) {
            jobId = response.data.job.id
          }
          if (response.data && response.data.jobs && response.data.jobs[0] && response.data.jobs[0].id) {
            jobId = response.data.jobs[0].id
          }
          if (!jobId) {
            throw new Error(e.message)
          }

          Swal.fire({
            type: "success",
            title: "Job Started",
            text: "A process is running in the backround to create the packages matching your channel. This may take a few minutes.",
            confirmButtonText: "OK",
          })

          this.watchEncoding({ jobId, currentChannel, adSlate, sourceMedia })
        } catch (e) {
          let text = e.message
          if (e.message && e.message.indexOf(textAbout3Jobs)) {
            text = textAbout3Jobs
          }
          Swal.fire({
            type: "error",
            title: "Cannot create ad slate",
            text,
            confirmButtonText: "OK",
          })
        }
      } else {
        Swal.fire({
          type: "error",
          title: "Cannot create ad slate",
          text: e.message,
          confirmButtonText: "OK",
        })
      }
    }
    this.dealWithNullableObjects(currentChannel)
    this._setState({ currentAdSlateId: event.target.value, currentChannel })
  }

  watchEncoding({ jobId, currentChannel, adSlate, sourceMedia }) {
    let progress = 1
    this._setState({ encodingProgress: progress })

    let encodingWatcher = async () => {
      let job = {}
      try {
        job = await backend.get("jobs", jobId)
      } catch (ex) {
        console.error(ex)
      }
      if (progress > 99) progress = 70
      if (job.status === "running") {
        progress = progress + 2
        setTimeout(() => encodingWatcher(), 5000)
      } else if (job.status === "done") {
        this._setState({ encodingProgress: 100 })
        try {
          const resultingMedia = await backend.putChannelAsset({
            titleId: adSlate.id,
            channelId: currentChannel.id,
            channelType: currentChannel.type,
            profile: currentChannel.profile,
            mediaUrl: sourceMedia.url,
            name: adSlate.name,
            http: currentChannel.http || false,
          })
          currentChannel.adSlateManifestUrl = resultingMedia.url
          this.dealWithNullableObjects(currentChannel)

          this._setState({ currentChannel })
          await Swal.fire({
            type: "success",
            title: "Sucessfully encoded ad-slate",
            confirmButtonText: "OK",
          })
          progress = false
        } catch (e) {
          await Swal.fire({
            type: "error",
            title: "Error while trying to encode ad-slate",
            text: "Please try again later or contact support. Error details: " + e.message,
            confirmButtonText: "OK",
          })
          progress = false
        }
      } else if (job.status === "failed") {
        const errorDetails = job.tasks
          .filter((task) => task.error)
          .map((task) => task.error)
          .join(", ")
        console.error(job)
        Swal.fire({
          type: "error",
          title: "Error while trying to encode ad-slate",
          text: "Please try again later or contact support. Error details: " + errorDetails,
          confirmButtonText: "OK",
        })
        progress = false
      }
      this._setState({ encodingProgress: progress })
    }
    encodingWatcher()
  }

  handleConfirmReplaceProfile = async (event, profileWarning) => {
    let message = ""

    if (profileWarning && profileWarning.length > 0) {
      message = '<div style="font-size: 12px; text-align: left;"><ul>'
      message += profileWarning
        .map((w) => {
          let msg = w.message
          if (w.data) {
            msg += `: ${w.data.width}x${w.data.height}`
          }
          return `<li>${msg}</li>`
        })
        .join("")
      message += "</ul></div>"
    }

    let html = '<p style="font-weight: bold;">Are you sure you want to replace the current profile?</p>'
    html += message
    html +=
      '<p style="font-size: 0.8em; text-align: left; margin-bottom: 0; font-family: monospace;">Current profile:</p>'
    html += `<textarea style="width: 100%; height: 200px;">${JSON.stringify(this.state.currentChannel.profile, null, 2)}</textarea>`
    html += '<p style="font-size: 0.8em; text-align: left; margin-bottom: 0; font-family: monospace;">New profile:</p>'
    html += `${message}<textarea style="width: 100%; height: 200px;">${JSON.stringify(this.state.newProfileInfo.data.profile, null, 2)}</textarea>`

    showConfirmationMessage(
      () => {
        const currentChannel = this.state.currentChannel

        currentChannel.profile = this.state.newProfileInfo.data.profile
        // **TODO - this duplicates functionality from the service, but I can't think of a neat way to expose it right now.
        currentChannel.type = this.state.newProfileInfo.data.type

        currentChannel.isMultiPeriod = this.state.newProfileInfo.data.isMultiPeriod
        currentChannel.compatibilityHlsMedia = this.state.newProfileInfo.data.compatibilityHlsMedia
        currentChannel.minimumSourceUpdatePeriod = this.state.newProfileInfo.data.minimumSourceUpdatePeriod
        currentChannel.setScanningFrequency = this.state.newProfileInfo.data.setScanningFrequency
        currentChannel.dynamicConfiguration = this.state.newProfileInfo.data.dynamicConfiguration
        currentChannel.fallbackSourceTags = this.state.newProfileInfo.data.fallbackSourceTags
        currentChannel.passThroughCaidSegmentation = this.state.newProfileInfo.data.passThroughCaidSegmentation
        currentChannel.cohortsEnabled = this.state.newProfileInfo.data.cohortsEnabled
        currentChannel.xApiKey = this.state.newProfileInfo.data.xApiKey
        currentChannel.cacheList = this.state.newProfileInfo.data.cacheList
        currentChannel.trueSourceUrl = this.state.newProfileInfo.trueSourceUrl
        currentChannel.prefetchDuration = this.state.newProfileInfo.data.prefetchDuration
        currentChannel.cacheTime = this.state.newProfileInfo.data.cacheTime
        this.dealWithNullableObjects(currentChannel)

        this._setState({
          currentChannel,
          profileOpen: true,
          newProfileInfo: {
            ...this.state.newProfileInfo,
            analyzed: false,
          },
        })
        if (this.state.newProfileInfo.data.isLive) {
          this.setAdSlate()
        }
      },
      {
        title: "Update Profile",
        html,
        confirmButtonText: "Replace",
        onCancel: () => {
          this._setState({
            newProfileInfo: {
              analyzed: false,
            },
          })
        },
      },
    )
  }

  handleAnalyse = async (event) => {
    event.persist()
    try {
      this._setState({
        analysing: true,
      })

      const currentChannel = this.state.currentChannel
      let sourceURL = currentChannel.sourceManifestUrl.split(":")
      currentChannel.http = sourceURL[0] === "http" ? true : false
      const proxyManifestUrl = await backend.getProxyManifestUrl(currentChannel.sourceManifestUrl)
      const manifestURL = proxyManifestUrl || currentChannel.sourceManifestUrl
      currentChannel.sourceManifestUrl = manifestURL
      const urlObject = new URL(manifestURL)
      const extension = path.extname(urlObject.pathname)
      if (extension === ".mp4" && (!currentChannel.profile || currentChannel.profile === "")) {
        this._setState({
          analysing: false,
          profileOpen: true,
        })

        // currentChannel.type = 'dash-vod'
        currentChannel.profile = {} // so the profile section will open

        showErrorMessage(
          'We cannot automatically create encoding profiles for mp4s. If you know what the encoding profile should look like for your mp4s, then you can enter it manually in the "Profile" section.',
        )
      } else if (extension === ".mrss") {
        this._setState({
          analysing: false,
          profileOpen: false,
        })
        showErrorMessage(
          "You cannot directly use an MRSS feed as a channel source. Please use instead a url link to one typical playlist file  (.m3u8, .mpd) from the MRSS feed.",
        )
      } else {
        const profileInfo = await backend.getProfile({
          sourceManifestUrl: manifestURL,
          apiKey: currentChannel.apiKey,
          manifestAnalysisOnly: this.state.manifestAnalysisOnly,
        })
        if (profileInfo.error) {
          this._setState({ analysing: false })
          let message
          switch (profileInfo.error.type) {
            case "NO_MASTER_MANIFEST":
              message = `<p>Please provide a master manifest. Further details can be found under:
              <a href="https://nowtilus.gitbook.io/serverside-ai/source-stream/packaging/hls-live-stream" target="_blank" rel="noopener noreferrer">
              https://nowtilus.gitbook.io/serverside-ai/source-stream/packaging/hls-live-stream</a></p>`
              break
            default:
              message = profileInfo.error.message
              break
          }
          showErrorMessage({ html: message })
        } else {
          if (this.props.id !== null) {
            this._setState({
              analysing: false,
              newProfileInfo: {
                analyzed: true,
                data: {
                  profile: profileInfo.profile,
                  type: `${profileInfo.manifestType}-${profileInfo.isLive ? "live" : "vod"}`,
                  isMultiPeriod: !!profileInfo.isMultiPeriod,
                  isLive: profileInfo.isLive,
                },
              },
              isAnalyzed: true,
            })

            if (
              JSON.stringify(this.state?.currentChannel?.profile) !==
              JSON.stringify(this.state?.newProfileInfo?.data?.profile)
            ) {
              this.handleConfirmReplaceProfile(event, profileInfo.warnings)
            }
            // if (profileInfo.warnings && profileInfo.warnings.length > 0) {
            //   let message = profileInfo.warnings.map(w => {
            //     let msg = w.message
            //     if (w.data) {
            //       msg += `: ${w.data.width}x${w.data.height}`
            //     }
            //     return `<p>${msg}</p>`
            //   }).join('')
            //   await showWarningMessage({ title: 'Warning', html: message })
            // }
            return
          }

          currentChannel.profile = profileInfo.profile
          // **TODO - this duplicates functionality from the service, but I can't think of a neat way to expose it right now.
          currentChannel.type = `${profileInfo.manifestType}-${profileInfo.isLive ? "live" : "vod"}`

          currentChannel.isMultiPeriod = !!profileInfo.isMultiPeriod
          currentChannel.compatibilityHlsMedia = !!profileInfo.compatibilityHlsMedia
          currentChannel.minimumSourceUpdatePeriod = profileInfo.minimumSourceUpdatePeriod
          currentChannel.setScanningFrequency = !!profileInfo.setScanningFrequency
          currentChannel.dynamicConfiguration = !!profileInfo.dynamicConfiguration
          currentChannel.fallbackSourceTags = !!profileInfo.fallbackSourceTags
          currentChannel.passThroughCaidSegmentation = !!profileInfo.passThroughCaidSegmentation
          currentChannel.cohortsEnabled = !!profileInfo.cohortsEnabled
          currentChannel.xApiKey = profileInfo.xApiKey || ""
          currentChannel.cacheList = profileInfo.cacheList || ""
          currentChannel.trueSourceUrl = profileInfo.trueSourceUrl || ""
          currentChannel.prefetchDuration = profileInfo.prefetchDuration || 0
          currentChannel.cacheTime = profileInfo.cacheTime || 0
          currentChannel.includeMediaTracks = profileInfo.includeMediaTracks
          this.dealWithNullableObjects(currentChannel)

          this._setState({
            currentChannel: {
              ...currentChannel,
              includeMediaTracks: {
                ...(currentChannel.includeMediaTracks || {
                  iFrames: false,
                  subtitles: false,
                  closedCaptions: false,
                }),
              },
            },
            analysing: false,
            profileOpen: true,
            isAnalyzed: true,
          })
          if (profileInfo.isLive) {
            this.setAdSlate()
          }

          if (profileInfo.warnings && profileInfo.warnings.length > 0) {
            let message = profileInfo.warnings
              .map((w) => {
                let msg = w.message
                if (w.data) {
                  msg += `: ${w.data.width}x${w.data.height}`
                }
                return `<p>${msg}</p>`
              })
              .join("")
            await showWarningMessage({ title: "Warning", html: message })
          }
        }
      }
    } catch (err) {
      this._setState({
        analysing: false,
      })
      await showErrorMessage("Error while Analysing: " + err.message)
    }
  }

  renderRoundQuestionMark = (key) => {
    if (!key) {
      return
    }
    if (!this.state.popovers[key]) {
      return
    }
    const open = !!this.state.popovers[key].anchorEl
    const id = "id_" + key
    return (
      <React.Fragment>
        <Button
          style={roundButtonStyle}
          aria-owns={open ? id : undefined}
          aria-haspopup="true"
          color="white"
          onMouseEnter={this.handlePopoverClick(key)}
          onMouseLeave={this.handlePopoverClose(key)}
          onClick={this.handlePopoverClick(key)}
        >
          ?
        </Button>
        <Popover
          style={{ pointerEvents: "none" }}
          id={id}
          open={open}
          anchorEl={this.state.popovers[key].anchorEl}
          onClose={this.handlePopoverClose(key)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableRestoreFocus
        >
          <Typography>{this.state.popovers[key].text}</Typography>
        </Popover>
      </React.Fragment>
    )
  }

  handleTypeChange = (e) => {
    const currentChannel = this.state.currentChannel
    const profile = {
      ...currentChannel.profile,
      manifestType: e.target.value,
    }
    this._setState({
      currentChannel: {
        ...currentChannel,
        type: e.target.value + "-" + currentChannel.type.split("-")[1],
        profile,
      },
    })
  }

  checkJson = async (data, titleMessage) => {
    if (!data) {
      return data // whatever... why not
    }
    const original = data
    let clean = xss(data, xssOptions)
    const regexp = /onload|onerror|innerHTML|document\.write\(|setAttribute\(|javascript|script|eval|alert/gi
    const matches = original.match(regexp)
    if (original !== clean || matches) {
      let originalText = matches[0]
      for (let i = 0; i < Math.min(original.length, clean.length); i++) {
        if (original[i] !== clean[i]) {
          originalText = original.substring(i + 1, i + 26)
          i = Math.min(original.length, clean.length)
        }
      }
      clean = clean.replace(regexp, "__REPLACED__")

      const textMessage = `Invalid Entry, JSON contains forbidden script: <br />${originalText.substring(0, 25)} .. <br />
          Suspicious input was deleted. Please check your changes.`

      await Swal.fire({
        type: "error",
        title: titleMessage,
        html: textMessage,
        confirmButtonText: "OK",
      })
    }

    return clean
  }

  handlePresetChange = (e) => this.modifyCurrentChannel({ presetId: e.target.value })

  getChannelTypeShort = (channel) => {
    if (channel.type.includes("vod")) {
      return "vod"
    }
    if (channel.type.includes("live")) {
      return "live"
    }

    return ""
  }

  safeValueAsNumber = (target) => {
    //else "0" stays "0" and does not become 0
    return target.value === "0" ? 0 : target.valueAsNumber || target.value
  }

  toggleBeacon = (beaconName, enabled) => {
    console.log(beaconName, enabled)
    const fixedOrder = ["impression", "start", "firstQuartile", "midpoint", "thirdQuartile", "complete"]
    const events = this.state.currentChannel.internalBeacons && this.state.currentChannel.internalBeacons.events
    if (events) {
      const index = events.indexOf(beaconName)
      if (enabled && index < 0) {
        events.push(beaconName)
      }
      if (!enabled && index >= 0) {
        events.splice(index, 1)
      }
      events.sort((a, b) => fixedOrder.indexOf(a) - fixedOrder.indexOf(b))
      this.modifyCurrentChannel({
        internalBeacons: { ...this.state.currentChannel.internalBeacons, baseUrl: this.state.baseUrl, events },
      })
    } else if (enabled) {
      this.modifyCurrentChannel({ internalBeacons: { baseUrl: this.state.baseUrl, events: [beaconName] } })
    }
  }

  isHMACEnabled() {
    let hmac = (this.state.currentChannel.authentication && this.state.currentChannel.authentication.hmac) || {}
    return hmac && Object.keys(hmac).length !== 0
  }

  changeHMACValue({ secret, method, window, cookieDomain, isSecretEncrypted }) {
    const currentChannel = this.state.currentChannel
    currentChannel.authentication = {
      ...currentChannel.authentication,
      hmac: {
        secret,
        isSecretEncrypted,
        method,
        window,
        cookieDomain,
      },
    }
    this._setState({ currentChannel })
  }

  render() {
    const xs = [4, 2, 8],
      sm = [4, 2, 8],
      md = [3, 2, 7],
      lg = [3, 2, 7]
    window.state = this.state
    window._this = this
    const beaconsEvents =
      (this.state.currentChannel.internalBeacons && this.state.currentChannel.internalBeacons.events) || []
    const beaconsLiveList = ["hls-live", "dash-live"]

    const { goBack } = this.props

    const classes = this.props.classes

    const {
      id,
      type,
      sourceManifestUrl,
      name,
      outputUri,
      apiKey,
      adSource,
      adSlateManifestUrl,
      stitcher,
      profile,
      preRoll,
      enablePreRoll,
      dvrWindow,
      startAtDvrWindow,
      overwriteGroupId,
      suggestedPresentationDelay,
    } = this.state.currentChannel

    const useAudioNormalization =
      !!this.state.currentChannel.profile.audioNormalization &&
      this.state.currentChannel.profile.useAudioNormalization !== false
    const audioNormalization = this.state.currentChannel.profile.audioNormalization || audioNormalizationDefaults

    const hmac =
      (this.state.currentChannel.authentication && this.state.currentChannel.authentication.hmac) || defaultHmac

    const hlsIndependentMediaSequences =
      typeof this.state.currentChannel.hlsIndependentMediaSequences !== "undefined"
        ? this.state.currentChannel.hlsIndependentMediaSequences
        : false
    const hlsLegacyMediaSequenceHandling =
      typeof this.state.currentChannel.hlsLegacyMediaSequenceHandling !== "undefined"
        ? this.state.currentChannel.hlsLegacyMediaSequenceHandling
        : false

    const timeShiftWindow = this.state.currentChannel.timeShiftWindow
    const cmaf = this.state.currentChannel.profile.muxingType === "cmaf" || false
    const http = this.state.currentChannel.http || false
    const isMultiPeriod = this.state.currentChannel.isMultiPeriod || false
    const compatibilityHlsMedia = this.state.currentChannel.compatibilityHlsMedia || false
    const minimumSourceUpdatePeriod = this.state.currentChannel.minimumSourceUpdatePeriod
    const setScanningFrequency = this.state.currentChannel.setScanningFrequency || false
    const dynamicConfiguration = this.state.currentChannel.dynamicConfiguration || false
    const fallbackSourceTags = this.state.currentChannel.fallbackSourceTags || false
    const passThroughCaidSegmentation = this.state.currentChannel.passThroughCaidSegmentation || false
    const cohortsEnabled = this.state.currentChannel.cohortsEnabled || false
    const xApiKey = this.state.currentChannel.xApiKey || ""
    const cacheList = this.state.currentChannel.cacheList || ""
    const trueSourceUrl = this.state.currentChannel.trueSourceUrl || ""
    const prefetchDuration = this.state.currentChannel.prefetchDuration || 0
    const cacheTime = this.state.currentChannel.cacheTime || 0
    const hasSeparateAudio = this.state.currentChannel.profile.hasSeparateAudio || false
    const enableServerSideBeacons = beaconsLiveList.includes(type)
      ? this.state.currentChannel.enableServerSideBeacons || false
      : false
    const contentLocationReplacement = this.state.currentChannel.contentLocationReplacement || {
      regex: "",
      options: "",
    }
    const adLocationReplacement = this.state.currentChannel.adLocationReplacement || { regex: "", options: "" }
    const segmentationConfiguration = this.state.currentChannel.segmentationConfiguration || {}
    const generateTitleIdsUsingURL = this.state.currentChannel.generateTitleIdsUsingURL
    const hlsSuppressTags = this.state.currentChannel.hlsSuppressTags || []
    const preservePlaylistFilename = this.state.currentChannel.preservePlaylistFilename || false
    const hlsEnableIframeTracks =
      this.state.currentChannel.profile.hlsEnableIframeTracks ||
      this.state.currentChannel.includeMediaTracks.iFrames ||
      false
    const enableClosedCaptions = this.state.currentChannel.includeMediaTracks.closedCaptions || false
    const enableSubtitles = this.state.currentChannel.includeMediaTracks.subtitles || false

    const {
      fetching,
      saveButtonLabel,
      disableBackButton,
      disableSaveButton,
      analysing,
      profileOpen,
      contentLocationOpen,
      adLocationOpen,
      segmentationConfigurationOpen,
      manifestAnalysisOnly,
      encoderSystem,
    } = this.state

    const isBitmovin = encoderSystem && encoderSystem.type === "bitmovin"

    const isAnalyzed = this.state.isAnalyzed

    let channelType
    if (this.state.currentChannel.channelType)
      channelType = this.state.currentChannel.channelType || this.state.currentChannel.type
    else channelType = this.getChannelTypeShort(this.state.currentChannel)

    let validAdSourceSystems = []

    if (this.state?.schema?.adSource?.properties?.system) {
      validAdSourceSystems = this.state.schema.adSource.properties.system.enum
    }

    let validSpliceModes = []
    if (this.state?.schema?.segmentationConfiguration?.properties?.spliceMode) {
      validSpliceModes = this.state.schema.segmentationConfiguration.properties.spliceMode.enum
    }

    const backButton = (
      <Button
        style={{ margin: "20px 10px" }}
        variant="contained"
        onClick={() => {
          goBack()
        }}
        disabled={disableBackButton}
      >
        Back
      </Button>
    )

    let header
    let content

    if (this.state.status === 404) {
      content = (
        <CardBody>
          <h4>Channel not found</h4>
          {backButton}
        </CardBody>
      )
    } else if (!adSource) {
      content = (
        <CardBody>
          <h4>Error on page</h4>
          {backButton}
        </CardBody>
      )
    } else {
      header = (
        <CardHeader color="rose" icon>
          <Grid container>
            <Grid item xs={12}>
              <CardIcon color="rose">
                <TV />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Channels Details</h4>
            </Grid>
          </Grid>
        </CardHeader>
      )
      content = (
        <React.Fragment>
          <Card>
            {header}
            <CardBody>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={channelType === "vod"}
                      onChange={() => this.modifyCurrentChannel({ channelType: "vod" })}
                      value="vod"
                      name="radioChannelType_VOD"
                      aria-label="radioChannelType_VOD"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="VoD (The player will provide a MRSS playlist)"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={channelType === "live"}
                      onChange={() => this.modifyCurrentChannel({ channelType: "live" })}
                      value="live"
                      name="radioChannelType_Live"
                      aria-label="radioChannelType_Live"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Live (Providing a live-stream)"
                />
                <TextField
                  id="name"
                  label="Channel Name *"
                  placeholder="Enter channel Name"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.handleChange}
                  value={name}
                />
                <TextField
                  id="apiKey"
                  label="API key (optional)"
                  placeholder="Enter API key, if available"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.handleChange}
                  value={apiKey}
                />
                <div style={{ color: "red" }}>{this.getErrors("apiKey")}</div>

                <div style={{ color: "red" }}>{this.getErrors("name")}</div>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>HMAC</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("hmac")}
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          onChange={(e) => {
                            const authentication = this.state.currentChannel.authentication || {}
                            if (e.target.checked) {
                              authentication.hmac = authentication.hmac || defaultHmac
                            } else {
                              delete authentication.hmac
                            }
                            this.modifyCurrentChannel({ authentication })
                          }}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                          checked={this.isHMACEnabled()}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {this.isHMACEnabled() && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Secret</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("hmacSecret")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <HMACSecretOption
                            onSave={(result) => {
                              this.changeHMACValue({
                                ...hmac,
                                ...result,
                              })
                            }}
                            value={hmac.secret}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                  {this.isHMACEnabled() && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Method</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("hmacMethod")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <TextField
                            id="hmacMethod"
                            // label="API key (optional)"
                            placeholder="Method"
                            type="text"
                            className={classes.textField}
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                              this.changeHMACValue({
                                ...hmac,
                                method: e.target.value,
                              })
                            }
                            value={hmac.method}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                  {this.isHMACEnabled() && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Window</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("hmacWindow")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <TextField
                            id="hmacWindow"
                            // label="API key (optional)"
                            placeholder="Window"
                            type="text"
                            className={classes.textField}
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                              this.changeHMACValue({
                                ...hmac,
                                window: parseInt(e.target.value),
                              })
                            }
                            value={hmac.window}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                  {this.isHMACEnabled() && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Cookie Domain</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("hmacCookieDomain")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <TextField
                            id="hmacCookieDomain"
                            // label="API key (optional)"
                            placeholder="Cookie Domain"
                            type="text"
                            className={classes.textField}
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                              this.changeHMACValue({
                                ...hmac,
                                cookieDomain: e.target.value,
                              })
                            }
                            value={hmac.cookieDomain}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                </GridContainer>

                {this.state.currentChannel.outputUri && (
                  <TextField
                    id="outputUri"
                    label="Output URI"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={outputUri}
                    disabled={true}
                  />
                )}
                <div style={{ color: "red" }}>{this.getErrors("outputUri")}</div>
                {channelType === "live" && (
                  <GridContainer>
                    <GridItem xs={12} sm={9} md={10} lg={10}>
                      {contentLocationOpen && <label style={{ marginTop: "20px" }}>Content Location Replacement</label>}
                    </GridItem>
                    <GridItem xs={12} sm={3} md={2} lg={2}>
                      <Button
                        style={{ float: "right" }}
                        onClick={this.toggleContentLocation}
                        color={contentLocationOpen ? null : "success"}
                      >
                        {contentLocationOpen ? "Hide" : "Content Location Replacement"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                {contentLocationOpen && (
                  <Grid item xs={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <label>Regex</label>
                        <TextField
                          id="contentLocationReplacement.regex"
                          placeholder="Enter regex e.g. ^.*(\\$h.*token=)\\w*(&?.*)$ "
                          type="search"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={this.handleChange}
                          value={contentLocationReplacement.regex || ""}
                        />
                        <div style={{ color: "red" }}>{this.getErrors("contentLocationReplacement.regex")}</div>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <label>Options</label>
                        <TextField
                          id="contentLocationReplacement.options"
                          type="search"
                          className={classes.textField}
                          placeholder="Enter regex options e.g. gi"
                          margin="dense"
                          fullWidth
                          onChange={this.handleChange}
                          value={contentLocationReplacement.options || ""}
                        />
                        <div style={{ color: "red" }}>{this.getErrors("contentLocationReplacement.options")}</div>
                      </GridItem>
                    </GridContainer>
                  </Grid>
                )}
                {channelType === "live" && (
                  <GridContainer>
                    <GridItem xs={12} sm={9} md={10} lg={10}>
                      {adLocationOpen && <label style={{ marginTop: "20px" }}>Ad Location Replacement</label>}
                    </GridItem>
                    <GridItem xs={12} sm={3} md={2} lg={2}>
                      <Button
                        style={{ float: "right" }}
                        onClick={this.toggleAdLocation}
                        color={adLocationOpen ? null : "success"}
                      >
                        {adLocationOpen ? "Hide" : "Ad Location Replacement"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                {adLocationOpen && (
                  <Grid item xs={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <label>Regex</label>
                        <TextField
                          id="adLocationReplacement.regex"
                          placeholder="Enter regex e.g. ^.*(\\$h.*token=)\\w*(&?.*)$ "
                          type="search"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={this.handleChange}
                          value={adLocationReplacement.regex || ""}
                        />
                        <div style={{ color: "red" }}>{this.getErrors("adLocationReplacement.regex")}</div>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <label>Options</label>
                        <TextField
                          id="adLocationReplacement.options"
                          type="search"
                          className={classes.textField}
                          placeholder="Enter regex options e.g. gi"
                          margin="dense"
                          fullWidth
                          onChange={this.handleChange}
                          value={adLocationReplacement.options || ""}
                        />
                        <div style={{ color: "red" }}>{this.getErrors("adLocationReplacement.options")}</div>
                      </GridItem>
                    </GridContainer>
                  </Grid>
                )}
              </Grid>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h5>Specify Source</h5>
              <Grid item xs={12}>
                <div>{channelUrlText[channelType]}</div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <TextField
                          id="sourceManifestUrl"
                          name="sourceManifestUrl"
                          label="Channel URL *"
                          placeholder="Enter or paste a valid channel creation url"
                          type="search"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={this.handleChange}
                          value={sourceManifestUrl.replace(/\s/g, "")}
                        />
                        <div style={{ color: "red" }}>{this.getErrors("sourceManifestUrl")}</div>
                      </GridItem>

                      <GridItem xs={12} sm={3} md={2} lg={2}>
                        <Button
                          style={{ float: "right" }}
                          disabled={!sourceManifestUrl || analysing}
                          type={"submit"}
                          color={"info"}
                          onClick={async (e) => {
                            this.state.newProfileInfo.analyzed
                              ? this.handleConfirmReplaceProfile(e)
                              : this.handleAnalyse(e)
                          }}
                        >
                          {analysing
                            ? "Analysing..."
                            : this.state.newProfileInfo.analyzed &&
                                JSON.stringify(this.state?.currentChannel?.profile) !==
                                  JSON.stringify(this.state?.newProfileInfo?.data?.profile)
                              ? "Update Profile"
                              : "Analyse"}
                          <Spinner size={12} color={"#FFF"} radius={2} visible={analysing} />
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <div>example: https://live.unified-streaming.com/scte35/scte35.isml/.mpd</div>
                <GridContainer>
                  <GridItem xs={12} md={6} lg={6} xl={6}>
                    <GridItem>
                      <div>
                        <Switch
                          disabled={false}
                          checked={manifestAnalysisOnly}
                          onChange={(e) => this._setState({ manifestAnalysisOnly: e.target.checked })}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                        />{" "}
                        Manifest analysis only
                      </div>
                    </GridItem>

                    {isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={cmaf}
                            onChange={(e) => {
                              this.updateDraftValueForProfile(CURRENT_CHANNEL_PROFILE, {
                                muxingType: e.target.checked
                                  ? "cmaf"
                                  : defaultMuxingType(this.state.currentChannel.type),
                              })
                              // this.modifyProfile({
                              //   muxingType: e.target.checked
                              //     ? "cmaf"
                              //     : defaultMuxingType(this.state.currentChannel.type),
                              // })
                            }}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />{" "}
                          CMAF stream
                        </div>
                      </GridItem>
                    )}

                    {!type.includes("dash") && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={hasSeparateAudio}
                            onChange={(e) =>
                              this.updateDraftValueForProfile(CURRENT_CHANNEL_PROFILE, {
                                hasSeparateAudio: e.target.checked,
                              })
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />{" "}
                          Stream has separate audio playlist
                        </div>
                      </GridItem>
                    )}
                    {isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={http}
                            onChange={(e) => this.modifyCurrentChannel({ http: e.target.checked }, false)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />{" "}
                          http stream
                        </div>
                      </GridItem>
                    )}

                    {type.includes("hls") && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={hlsEnableIframeTracks}
                            onChange={(e) => {
                              // this.modifyProfile({ hlsEnableIframeTracks: e.target.checked })
                              this.updateDraftValueForProfile(CURRENT_CHANNEL_PROFILE, {
                                hlsEnableIframeTracks: e.target.checked,
                              })
                              this.modifyCurrentChannel({
                                includeMediaTracks: {
                                  ...this.state.currentChannel.includeMediaTracks,
                                  iFrames: e.target.checked,
                                },
                              })
                            }}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />{" "}
                          I-Frame Support
                        </div>
                      </GridItem>
                    )}
                    {type.includes("dash") && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={isMultiPeriod}
                            onChange={(e) => this.modifyCurrentChannel({ isMultiPeriod: e.target.checked }, false)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                          multi period
                        </div>
                      </GridItem>
                    )}
                    {type === "hls-live" && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={compatibilityHlsMedia}
                            onChange={(e) =>
                              this.modifyCurrentChannel({ compatibilityHlsMedia: e.target.checked }, false)
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                          Compatibility HLS Media
                        </div>
                      </GridItem>
                    )}

                    {channelType === "live" && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={setScanningFrequency}
                            onChange={(e) =>
                              this.modifyCurrentChannel({ setScanningFrequency: e.target.checked }, false)
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                          Set scanning frequency
                        </div>
                      </GridItem>
                    )}
                  </GridItem>

                  <GridItem xs={12} md={6} lg={6} xl={6}>
                    {/* Dynamic Configuration and what follows it  */}
                    {channelType === "live" && isAnalyzed && (
                      <GridItem xs={12} md={12} lg={12} xl={12}>
                        <div>
                          <Switch
                            disabled={false}
                            checked={dynamicConfiguration}
                            onChange={(e) =>
                              this.modifyCurrentChannel(
                                {
                                  dynamicConfiguration: e.target.checked,
                                  includeMediaTracks: {
                                    ...this.state.currentChannel.includeMediaTracks,
                                    subtitles: false,
                                    closedCaptions: false,
                                  },
                                },
                                false,
                              )
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                          Dynamic configuration
                        </div>
                      </GridItem>
                    )}

                    <GridItem>
                      {dynamicConfiguration && (
                        <GridItem zeroMinWidth>
                          <div>
                            <Switch
                              disabled={false}
                              checked={enableClosedCaptions}
                              onChange={(e) =>
                                this.modifyCurrentChannel(
                                  {
                                    includeMediaTracks: {
                                      ...this.state.currentChannel.includeMediaTracks,
                                      closedCaptions: e.target.checked,
                                    },
                                  },
                                  false,
                                )
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />{" "}
                            Closed Caption Support
                          </div>
                        </GridItem>
                      )}

                      {dynamicConfiguration && (
                        <GridItem zeroMinWidth>
                          <div>
                            <Switch
                              disabled={false}
                              checked={enableSubtitles}
                              onChange={(e) =>
                                this.modifyCurrentChannel(
                                  {
                                    includeMediaTracks: {
                                      ...this.state.currentChannel.includeMediaTracks,
                                      subtitles: e.target.checked,
                                    },
                                  },
                                  false,
                                )
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />{" "}
                            Subtitle Support
                          </div>
                        </GridItem>
                      )}

                      {channelType === "live" && dynamicConfiguration && (
                        <GridItem>
                          <div>
                            <Switch
                              disabled={false}
                              checked={cohortsEnabled}
                              onChange={(e) => this.modifyCurrentChannel({ cohortsEnabled: e.target.checked }, false)}
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                            Cohorts enabled
                          </div>
                        </GridItem>
                      )}

                      {channelType === "live" && dynamicConfiguration && (
                        <GridItem>
                          <div>
                            <Switch
                              disabled={false}
                              checked={passThroughCaidSegmentation}
                              onChange={(e) =>
                                this.modifyCurrentChannel({ passThroughCaidSegmentation: e.target.checked }, false)
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                            Pass through caid segmentation
                          </div>
                        </GridItem>
                      )}

                      {channelType === "live" && dynamicConfiguration && (
                        <GridItem>
                          <div>
                            <Switch
                              disabled={false}
                              checked={fallbackSourceTags}
                              onChange={(e) =>
                                this.modifyCurrentChannel({ fallbackSourceTags: e.target.checked }, false)
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                            Fallback source tags
                          </div>
                        </GridItem>
                      )}
                    </GridItem>
                  </GridItem>
                </GridContainer>
                {/* inputs */}
                {channelType === "live" && setScanningFrequency && (
                  <GridContainer>
                    <GridItem item xs={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={8} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Minimum source update period</div>
                        </GridItem>
                        <GridItem xs={4} sm={2} md={2} lg={1}>
                          {this.renderRoundQuestionMark("minimumSourceUpdatePeriod")}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <TextField
                            id="minimumSourceUpdatePeriod"
                            name="minimumSourceUpdatePeriod"
                            placeholder="Enter minimum source update period here"
                            type="text"
                            className={classes.textField}
                            margin="dense"
                            fullWidth
                            onChange={this.handleChange}
                            value={minimumSourceUpdatePeriod}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                )}
                {channelType === "live" && dynamicConfiguration && (
                  <Grid item xs={12}>
                    <TextField
                      id="trueSourceUrl"
                      label="True Source URL"
                      placeholder="Enter true source url here"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={trueSourceUrl || ""}
                    />
                    <TextField
                      id="xApiKey"
                      label="X-API-KEY"
                      placeholder="Enter X-API-KEY here"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={xApiKey || ""}
                    />
                    <TextField
                      id="prefetchDuration"
                      label="Prefetch Duration"
                      placeholder="Enter Prefetch Duration here"
                      type="number"
                      step="0.1"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={prefetchDuration || 0}
                    />
                    <TextField
                      id="cacheTime"
                      label="Cache time/segment length"
                      placeholder="Enter Cache time here"
                      type="number"
                      step="0.1"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={cacheTime || 0}
                    />
                    {cohortsEnabled && dynamicConfiguration && (
                      <TextField
                        id="cacheList"
                        label="Cache key list"
                        placeholder="<key>,<key>"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        onChange={this.handleChange}
                        value={cacheList || ""}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </CardBody>
          </Card>
          {hasSeparateAudio && isBitmovin && type === "hls-vod" && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Overwrite Audio GroupID</h5>
                  <div>This option is sometimes needed in case of HLS VoD with Bitmovin encoder</div>
                  <TextField
                    id="overwriteGroupId"
                    label="New audio group id"
                    placeholder="Enter new audio group id here"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={overwriteGroupId || ""}
                  />
                </Grid>
              </CardBody>
            </Card>
          )}
          {profile && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <GridContainer>
                    <GridItem xs={6} sm={9} md={10} lg={10}>
                      <h5>Profile</h5>
                    </GridItem>
                  </GridContainer>
                </Grid>
                <Grid item xs={12}>
                  <GridContainer>
                    <GridItem xs={6} sm={9} md={10} lg={10}>
                      <div>
                        If you have provided an example above the profile to condition your ads have already been
                        configured and you can move on with the next step. If you had no example, or want to fine tune
                        your profile, you can use the editor below.
                      </div>
                    </GridItem>
                    <GridItem xs={6} sm={3} md={2} lg={2}>
                      <Button
                        style={{ float: "right" }}
                        onClick={this.toggleProfile}
                        color={profileOpen ? null : "success"}
                      >
                        {profileOpen ? "Hide" : "View"}
                      </Button>
                    </GridItem>
                  </GridContainer>
                  {profileOpen && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={8} lg={8}>
                            <ResourceEnumDropdownWithStatus
                              style={{ width: "100%" }}
                              classes={classes}
                              handleChange={this.handleTypeChange}
                              value={this.state.currentChannel.type.split("-")[0]}
                              data={streamTypes}
                              inputLabelText={"Stream Type:"}
                              noEmpty={true}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  )}
                </Grid>
                {profileOpen && (
                  <Grid item xs={12}>
                    <GridContainer style={{ marginTop: "10px", marginBottom: "20px" }}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          multiline
                          id="profile"
                          label="Encoding Profile"
                          type="search"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={this.handleJSONFieldChange(CURRENT_CHANNEL_PROFILE)}
                          value={this.getDraftValue(CURRENT_CHANNEL_PROFILE)}
                        />
                        <div style={{ color: "red" }}>{this.getJSONError(CURRENT_CHANNEL_PROFILE)}</div>
                      </GridItem>
                    </GridContainer>
                  </Grid>
                )}
              </CardBody>
            </Card>
          )}

          <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Ad Server</h5>
                <div>
                  Please choose one of the provided ad servers and fill out the necessary parameters. If you are in
                  doubt, or have not set up your ad-server account yet, feel free to use Serverside.ai to get a
                  demo-campaign with example advertisements.
                </div>

                <TextField
                  id="adSource.url"
                  label="Ad Server URL (optional)"
                  placeholder="Enter Ad Server URL, if available"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.handleChange}
                  value={adSource?.url || ""}
                />
                <div style={{ color: "red" }}>{this.getErrors("adServerUrl")}</div>

                <ResourceEnumDropdown
                  name="adSource.system"
                  placeholder="Choose Ad Server Type"
                  enumArray={validAdSourceSystems}
                  theKey={"system"}
                  data={this.state.currentChannel.adSource}
                  handleChange={this.handleChange}
                  required={true}
                  classes={classes}
                />
                <div style={{ color: "red" }}>{this.getErrors("adServerType")}</div>

                <label>Ad Server Parameter</label>
                <TextField
                  multiline
                  id="adSource.params"
                  label="ad source parameters (optional)"
                  placeholder="Enter ad source params, if available"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.handleJSONFieldChange(CURRENT_CHANNEL_ADSOURCE_PARAMS)}
                  value={this.getDraftValue(CURRENT_CHANNEL_ADSOURCE_PARAMS)}
                />
                <div style={{ color: "red" }}>
                  {this.getJSONError(CURRENT_CHANNEL_ADSOURCE_PARAMS)}
                  {this.getLessThan15ByteError(CURRENT_CHANNEL_ADSOURCE_PARAMS)}
                </div>
              </Grid>
            </CardBody>
          </Card>
          {type === "hls-live" && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Preroll</h5>
                  <div>
                    In this section a preroll ad-pod can be configured that is displayed before your live-stream starts.
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Enable Preroll</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("enablePreRoll")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={enablePreRoll}
                            onChange={(e) => this.modifyCurrentChannel({ enablePreRoll: e.target.checked })}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                      {enablePreRoll === true && (
                        <div>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4} lg={3}>
                              <div style={{ float: "left", marginTop: "15px" }}>Maximum Duration</div>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={2} lg={1}>
                              {this.renderRoundQuestionMark("preRollMaxDuration")}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={2} lg={1}>
                              <TextField
                                id="preRoll.maxDuration"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                onChange={(e) =>
                                  this.modifyCurrentChannel({
                                    preRoll: { ...preRoll, maxDuration: this.safeValueAsNumber(e.target) },
                                  })
                                }
                                value={preRoll.maxDuration}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4} lg={3}>
                              <div style={{ float: "left", marginTop: "15px" }}>Maximum Count</div>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={2} lg={1}>
                              {this.renderRoundQuestionMark("preRollMaxCount")}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={2} lg={1}>
                              <TextField
                                id="preRoll.maxCount"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                onChange={(e) =>
                                  this.modifyCurrentChannel({
                                    preRoll: { ...preRoll, maxCount: this.safeValueAsNumber(e.target) },
                                  })
                                }
                                value={preRoll.maxCount}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={4} sm={4} md={4} lg={3}>
                              <div style={{ float: "left", marginTop: "15px" }}>
                                Use dedicated ad-server for pre roll
                              </div>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={2} lg={1}>
                              <Switch
                                disabled={false}
                                checked={preRoll.useDifferentAdServer}
                                onChange={(e) =>
                                  this.modifyCurrentChannel({
                                    preRoll: { ...preRoll, useDifferentAdServer: e.target.checked },
                                  })
                                }
                                value="status"
                                color="primary"
                                size="small"
                                inputProps={{ "aria-label": "status checkbox" }}
                              />
                            </GridItem>
                            {preRoll.useDifferentAdServer === true && (
                              <GridItem item xs={12}>
                                <h5>Preroll Ad Server</h5>
                                <div></div>
                                <div style={{ color: "red" }}>{this.getErrors("apiKey")}</div>

                                <TextField
                                  id="preRoll.adSource.url"
                                  label="Ad Server URL"
                                  placeholder="Enter Ad Server URL"
                                  type="search"
                                  className={classes.textField}
                                  margin="dense"
                                  fullWidth
                                  onChange={this.handleChange}
                                  value={preRoll.adSource.url}
                                />
                                <div style={{ color: "red" }}>{this.getErrors("adServerUrl")}</div>

                                <ResourceEnumDropdown
                                  name="preRoll.adSource.system"
                                  placeholder="Choose Ad Server Type"
                                  enumArray={validAdSourceSystems}
                                  theKey={"system"}
                                  data={this.state.currentChannel.preRoll.adSource}
                                  handleChange={this.handleChange}
                                  required={true}
                                  classes={classes}
                                />
                                <div style={{ color: "red" }}>{this.getErrors("adServerType")}</div>

                                <label>Ad Server Parameter</label>
                                <TextField
                                  multiline
                                  id="preRoll.adSource.params"
                                  label="ad source parameters (optional)"
                                  placeholder="Enter ad source params, if available"
                                  type="search"
                                  className={classes.textField}
                                  margin="dense"
                                  fullWidth
                                  onChange={this.handleJSONFieldChange(CURRENT_CHANNEL_PREROLL_ADSOURCE_PARAMS)}
                                  value={this.getDraftValue(CURRENT_CHANNEL_PREROLL_ADSOURCE_PARAMS)}
                                />
                                <div style={{ color: "red" }}>
                                  {this.getJSONError(CURRENT_CHANNEL_PREROLL_ADSOURCE_PARAMS)}
                                </div>
                              </GridItem>
                            )}
                          </GridContainer>
                        </div>
                      )}
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          {type === "dash-live" && (
            <Card>
              <CardBody>
                <Grid>
                  <h5>Suggested Presentation Delay</h5>
                  <GridContainer>
                    <GridItem xs={12} sm={9} md={10} lg={10}>
                      <TextField
                        id="suggestedPresentationDelay"
                        name="suggestedPresentationDelay"
                        type="number"
                        placeholder="Enter value here"
                        className={classes.textField}
                        margin="dense"
                        onChange={(e) =>
                          this.modifyCurrentChannel({
                            suggestedPresentationDelay: e.target.value ? +e.target.value : 0,
                          })
                        }
                        value={suggestedPresentationDelay}
                      />
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          <Card>
            <CardBody>
              <Grid>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    {segmentationConfigurationOpen && (
                      <label style={{ marginTop: "20px" }}>Segmentation Configuration</label>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={3} md={2} lg={2}>
                    <Button
                      style={{ float: "right" }}
                      onClick={this.toggleSegmentationConfiguration}
                      color={segmentationConfigurationOpen ? null : "info"}
                    >
                      {segmentationConfigurationOpen ? "Hide" : "Segmentation Configuration"}
                    </Button>
                  </GridItem>
                </GridContainer>
                {segmentationConfigurationOpen && (
                  <Grid>
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={10} lg={10}>
                        <ResourceEnumDropdown
                          name="segmentationConfiguration.spliceMode"
                          id="segmentationConfiguration.spliceMode"
                          placeholder="Choose splice mode"
                          enumArray={validSpliceModes}
                          theKey={"spliceMode"}
                          data={segmentationConfiguration}
                          handleChange={(e) =>
                            this.modifyCurrentChannel(
                              {
                                segmentationConfiguration: { ...segmentationConfiguration, spliceMode: e.target.value },
                              },
                              false,
                            )
                          }
                          required={true}
                          classes={classes}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Segmentation Type Ids Start</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.segmentationTypeIdsStart")}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={6} lg={8}>
                        <TextField
                          id="segmentationConfiguration.segmentationTypeIdsStart"
                          name="segmentationConfiguration.segmentationTypeIdsStart"
                          placeholder="<timeSignal>,<timeSignal> ..."
                          className={classes.textField}
                          margin="dense"
                          onChange={(e) =>
                            this.modifyCurrentChannel({
                              segmentationConfiguration: {
                                ...segmentationConfiguration,
                                segmentationTypeIdsStart: e.target.value,
                              },
                            })
                          }
                          value={segmentationConfiguration.segmentationTypeIdsStart || ""}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Segmentation Type Ids End</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.segmentationTypeIdsEnd")}
                      </GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <TextField
                          id="segmentationConfiguration.segmentationTypeIdsEnd"
                          name="segmentationConfiguration.segmentationTypeIdsEnd"
                          placeholder="<timeSignal>,<timeSignal> ..."
                          className={classes.textField}
                          margin="dense"
                          onChange={(e) =>
                            this.modifyCurrentChannel({
                              segmentationConfiguration: {
                                ...segmentationConfiguration,
                                segmentationTypeIdsEnd: e.target.value,
                              },
                            })
                          }
                          value={segmentationConfiguration.segmentationTypeIdsEnd || ""}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Scte35 is Hex not Base64 </div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.scte35IsHexNotBase64")}
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={segmentationConfiguration.scte35IsHexNotBase64}
                          onChange={(e) =>
                            this.modifyCurrentChannel({
                              segmentationConfiguration: {
                                ...segmentationConfiguration,
                                scte35IsHexNotBase64: e.target.checked,
                              },
                            })
                          }
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Duration from Scte35 over manifest </div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.durationFromScte35OverManifest")}
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={segmentationConfiguration.durationFromScte35OverManifest}
                          onChange={(e) =>
                            this.modifyCurrentChannel({
                              segmentationConfiguration: {
                                ...segmentationConfiguration,
                                durationFromScte35OverManifest: e.target.checked,
                              },
                            })
                          }
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Default Duration</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.defaultDuration")}
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <TextField
                          id="segmentationConfiguration.defaultDuration"
                          type="number"
                          className={classes.textField}
                          margin="dense"
                          onChange={(e) =>
                            this.modifyCurrentChannel(
                              {
                                segmentationConfiguration: {
                                  ...segmentationConfiguration,
                                  defaultDuration: this.safeValueAsNumber(e.target),
                                },
                              },
                              false,
                            )
                          }
                          value={segmentationConfiguration.defaultDuration || ""}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: "left", marginTop: "15px" }}>Fallback source tags</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        {this.renderRoundQuestionMark("segmentationConfiguration.fallbackSourceTags")}
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={segmentationConfiguration.fallbackSourceTags || false}
                          onChange={(e) =>
                            this.modifyCurrentChannel({
                              segmentationConfiguration: {
                                ...segmentationConfiguration,
                                fallbackSourceTags: e.target.checked,
                              },
                            })
                          }
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={3} md={2} lg={2}>
                        <Button
                          style={{ float: "left" }}
                          onClick={() => {
                            this.clearSegmentationConfiguration()
                          }}
                          color={"warning"}
                        >
                          Clear
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </Grid>
                )}
              </Grid>
            </CardBody>
          </Card>

          {beaconsLiveList.includes(type) && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Ad beacons</h5>
                  <div>
                    In this section you can choose to use server-side ad-beacons, instead of the default client-side
                    beacons
                  </div>
                  <GridContainer>
                    <GridItem>
                      <div>
                        <Switch
                          disabled={false}
                          checked={enableServerSideBeacons}
                          onChange={(e) =>
                            this.modifyCurrentChannel({ enableServerSideBeacons: e.target.checked }, false)
                          }
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ "aria-label": "status checkbox" }}
                        />{" "}
                        Use server-side ad-beacons
                      </div>
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          {!enableServerSideBeacons && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Beacons Settings</h5>
                  <div>Here you can switch beacons on and off</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Impression</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconImpression")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("impression") >= 0}
                            onChange={(e) => this.toggleBeacon("impression", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Start</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconStart")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("start") >= 0}
                            onChange={(e) => this.toggleBeacon("start", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>First Quartile</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconFirstQuartile")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("firstQuartile") >= 0}
                            onChange={(e) => this.toggleBeacon("firstQuartile", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Midpoint</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconMidpoint")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("midpoint") >= 0}
                            onChange={(e) => this.toggleBeacon("midpoint", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Third Quartile</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconThirdQuartile")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("thirdQuartile") >= 0}
                            onChange={(e) => this.toggleBeacon("thirdQuartile", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Complete</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconComplete")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("complete") >= 0}
                            onChange={(e) => this.toggleBeacon("complete", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={3}>
                          <div style={{ float: "left", marginTop: "15px" }}>Click</div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          {this.renderRoundQuestionMark("beaconClick")}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={2} lg={1}>
                          <Switch
                            disabled={false}
                            checked={beaconsEvents.indexOf("click") >= 0}
                            onChange={(e) => this.toggleBeacon("click", e.target.checked)}
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          {channelType === "live" && (
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: "left", marginTop: "15px" }}>Timeshift Window</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    {this.renderRoundQuestionMark("timeShiftWindow")}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <TextField
                      id="timeShiftWindow"
                      type="number"
                      InputProps={{
                        inputProps: {
                          max: 3600,
                          min: 0,
                        },
                      }}
                      className={classes.textField}
                      margin="dense"
                      onChange={(e) =>
                        this.modifyCurrentChannel({ timeShiftWindow: this.safeValueAsNumber(e.target) }, false)
                      }
                      value={timeShiftWindow}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: "left", marginTop: "15px" }}>Playlist size</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    {this.renderRoundQuestionMark("dvrWindow")}
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <TextField
                      id="dvrWindow"
                      type="number"
                      InputProps={{
                        inputProps: {
                          max: 3600,
                          min: 0,
                        },
                      }}
                      className={classes.textField}
                      margin="dense"
                      onChange={(e) =>
                        this.modifyCurrentChannel({ dvrWindow: this.safeValueAsNumber(e.target) }, false)
                      }
                      value={dvrWindow}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={2}>
                    <div style={{ float: "left", marginTop: "15px" }}>Growing Playlist</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    {this.renderRoundQuestionMark("startAtDvrWindow")}
                  </GridItem>
                  <Switch
                    disabled={false}
                    checked={!startAtDvrWindow}
                    onChange={(e) => {
                      const currentChannel = this.state.currentChannel
                      currentChannel.startAtDvrWindow = !e.target.checked
                      this._setState({ currentChannel })
                    }}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ "aria-label": "status checkbox" }}
                  />
                </GridContainer>
                {type === "hls-live" && (
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: "left", marginTop: "15px" }}>Independent Media Sequences</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      {this.renderRoundQuestionMark("hlsIndependentMediaSequences")}
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <Switch
                        disabled={false}
                        checked={hlsIndependentMediaSequences}
                        onChange={(e) => {
                          const currentChannel = this.state.currentChannel
                          currentChannel.hlsIndependentMediaSequences = e.target.checked
                          if (e.target.checked && currentChannel.hlsLegacyMediaSequenceHandling) {
                            currentChannel.hlsLegacyMediaSequenceHandling = false
                          }
                          this._setState({ currentChannel })
                        }}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ "aria-label": "status checkbox" }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                {type === "hls-live" && (
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: "left", marginTop: "15px" }}>Legacy Media Sequence Handling</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      {this.renderRoundQuestionMark("hlsLegacyMediaSequenceHandling")}
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <Switch
                        disabled={false}
                        checked={hlsLegacyMediaSequenceHandling}
                        onChange={(e) => {
                          const currentChannel = this.state.currentChannel
                          currentChannel.hlsLegacyMediaSequenceHandling = e.target.checked
                          if (e.target.checked && currentChannel.hlsIndependentMediaSequences) {
                            currentChannel.hlsIndependentMediaSequences = false
                          }
                          this._setState({ currentChannel })
                        }}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ "aria-label": "status checkbox" }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                {type === "hls-live" && (
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: "left", marginTop: "15px" }}>Suppress HLS Tags</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      {this.renderRoundQuestionMark("suppressHLSTags")}
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <ResourceSuppressHLSTagsDropdown
                        style={{ width: "350px" }}
                        classes={classes}
                        handleChange={(value) => {
                          this._setState({
                            currentChannel: {
                              ...this.state.currentChannel,
                              hlsSuppressTags: value,
                            },
                          })
                        }}
                        value={hlsSuppressTags}
                        data={[
                          "PROGRAM-DATE-TIME",
                          "ALLOW-CACHE",
                          "DATERANGE",
                          "I-FRAMES-ONLY",
                          "I-FRAME-STREAM-INF",
                          "SESSION-DATA",
                          "INDEPENDENT-SEGMENTS",
                          "DISCONTINUITY-SEQUENCE",
                          "START",
                          "GAP",
                        ]}
                        inputLabelText={"Suppress HLS Tags"}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                {type === "hls-live" && (
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: "left", marginTop: "15px" }}>Preserve playlist name</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      {this.renderRoundQuestionMark("preservePlaylistFilename")}
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <Switch
                        disabled={false}
                        checked={preservePlaylistFilename || false}
                        onChange={(e) =>
                          this.modifyCurrentChannel({ preservePlaylistFilename: e.target.checked }, false)
                        }
                        value={preservePlaylistFilename || false}
                        color="primary"
                        size="small"
                        inputProps={{ "aria-label": "status checkbox" }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          )}
          {channelType === "vod" && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Advanced Settings</h5>
                  <div>This section provides advanced settings to fine tune your channel configuration.</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                          <div style={{ float: "left", marginTop: "15px" }}>Custom http proxy url</div>
                        </GridItem>
                        <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                          {this.renderRoundQuestionMark("httpProxyUrl")}
                        </GridItem>
                        <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                          <TextField
                            id="stitcher.httpProxyUrl"
                            name="stitcher.httpProxyUrl"
                            label="custom http proxy url"
                            placeholder="Enter or paste a valid http proxy url"
                            type="search"
                            className={classes.textField}
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                              this.modifyCurrentChannel({ stitcher: { ...stitcher, httpProxyUrl: e.target.value } })
                            }
                            value={stitcher.httpProxyUrl || ""}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                          <div style={{ float: "left", marginTop: "15px" }}>Use relative segment url</div>
                        </GridItem>
                        <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                          {this.renderRoundQuestionMark("shouldUseRelativeSegmentUrl")}
                        </GridItem>
                        <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                          <Switch
                            disabled={false}
                            checked={stitcher.shouldUseRelativeSegmentUrl}
                            onChange={(e) =>
                              this.modifyCurrentChannel({
                                stitcher: { ...stitcher, shouldUseRelativeSegmentUrl: e.target.checked },
                              })
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                          <div style={{ float: "left", marginTop: "15px" }}>Segments to replay after midroll</div>
                        </GridItem>
                        <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                          {this.renderRoundQuestionMark("numberOfSegmentsToReplayAfterMidroll")}
                        </GridItem>
                        <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                          <TextField
                            id="textField_numberOfSegmentsToReplayAfterMidroll"
                            type="number"
                            className={classes.textField}
                            margin="dense"
                            onChange={(e) =>
                              this.modifyCurrentChannel({
                                stitcher: {
                                  ...stitcher,
                                  numberOfSegmentsToReplayAfterMidroll: this.safeValueAsNumber(e.target),
                                },
                              })
                            }
                            value={stitcher.numberOfSegmentsToReplayAfterMidroll}
                          />
                        </GridItem>
                      </GridContainer>
                      {type === "dash-vod" && (
                        <GridContainer>
                          <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                            <div style={{ float: "left", marginTop: "15px" }}>Include DASH events</div>
                          </GridItem>
                          <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                            {this.renderRoundQuestionMark("includeDashEvents")}
                          </GridItem>
                          <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                            <Switch
                              disabled={false}
                              checked={stitcher.includeDashEvents}
                              onChange={(e) =>
                                this.modifyCurrentChannel({
                                  stitcher: { ...stitcher, includeDashEvents: e.target.checked },
                                })
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                      {type === "hls-vod" && (
                        <GridContainer>
                          <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                            <div style={{ float: "left", marginTop: "15px" }}>Filter HLS by Bandwidth</div>
                          </GridItem>
                          <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                            {this.renderRoundQuestionMark("shouldFilterHlsByBandwidth")}
                          </GridItem>
                          <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                            <Switch
                              disabled={false}
                              checked={stitcher.shouldFilterHlsByBandwidth || false}
                              onChange={(e) =>
                                this.modifyCurrentChannel({
                                  stitcher: { ...stitcher, shouldFilterHlsByBandwidth: e.target.checked },
                                })
                              }
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                      {type === "hls-vod" && (
                        <GridContainer>
                          <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                            <div style={{ float: "left", marginTop: "15px" }}>Maximum HLS Bandwidth</div>
                          </GridItem>
                          <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                            {this.renderRoundQuestionMark("bandwidthThreshold")}
                          </GridItem>
                          <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                            <TextField
                              id="textField_bandwidthThreshold"
                              className={classes.textField}
                              margin="dense"
                              onChange={(e) =>
                                this.modifyCurrentChannel({
                                  stitcher: { ...stitcher, bandwidthThreshold: e.target.checked },
                                })
                              }
                              value={stitcher.bandwidthThreshold}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                      <GridContainer>
                        <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                          <div style={{ float: "left", marginTop: "15px" }}>Use SCTE-35 Ad-Markers</div>
                        </GridItem>
                        <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                          {this.renderRoundQuestionMark("useScte35")}
                        </GridItem>
                        <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                          <Switch
                            disabled={false}
                            checked={stitcher.useScte35 || false}
                            onChange={(e) =>
                              this.modifyCurrentChannel({ stitcher: { ...stitcher, useScte35: e.target.checked } })
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                          <div style={{ float: "left", marginTop: "15px" }}>Replace Ad-Breaks</div>
                        </GridItem>
                        <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                          {this.renderRoundQuestionMark("replaceInsteadOfInsert")}
                        </GridItem>
                        <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                          <Switch
                            disabled={false}
                            checked={stitcher.replaceInsteadOfInsert || false}
                            onChange={(e) =>
                              this.modifyCurrentChannel({
                                stitcher: { ...stitcher, replaceInsteadOfInsert: e.target.checked },
                              })
                            }
                            value="status"
                            color="primary"
                            size="small"
                            inputProps={{ "aria-label": "status checkbox" }}
                          />
                        </GridItem>
                      </GridContainer>
                      {(type === "hls-vod" || type === "dash-vod") && (
                        <GridContainer>
                          <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                            <div style={{ float: "left", marginTop: "15px" }}>Provide VAST URL</div>
                          </GridItem>
                          <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                            {this.renderRoundQuestionMark("provideVastURL")}
                          </GridItem>
                          <GridItem xs={xs[-1]} sm={sm[-1]} md={md[-1]} lg={lg[-1]}>
                            <Switch
                              disabled={false}
                              checked={stitcher.provideVastURL || false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.modifyCurrentChannel({
                                    stitcher: { ...stitcher, provideVastURL: e.target.checked },
                                  })
                                } else {
                                  this.modifyCurrentChannel({
                                    stitcher: {
                                      ...stitcher,
                                      provideVastURL: e.target.checked,
                                      removeTrackingTemplate: false,
                                    },
                                  })
                                }
                              }}
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                          </GridItem>
                          {stitcher.provideVastURL && (
                            <>
                              <GridItem xs={xs[1.5]} sm={sm[1.5]} md={md[1.5]} lg={lg[1.5]}>
                                <div style={{ float: "left", marginTop: "15px" }}>
                                  Remove Tracking template from body
                                </div>
                              </GridItem>
                              <GridItem xs={xs[-1]} sm={sm[-1]} md={md[-1]} lg={lg[-1]}>
                                <Switch
                                  disabled={false}
                                  checked={stitcher.removeTrackingTemplate || false}
                                  onChange={(e) =>
                                    this.modifyCurrentChannel({
                                      stitcher: { ...stitcher, removeTrackingTemplate: e.target.checked },
                                    })
                                  }
                                  value="status"
                                  color="primary"
                                  size="small"
                                  inputProps={{ "aria-label": "status checkbox" }}
                                />
                              </GridItem>
                            </>
                          )}
                        </GridContainer>
                      )}
                      {type === "hls-vod" && (
                        <GridContainer>
                          <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                            <div style={{ float: "left", marginTop: "15px" }}>Preserve playlist name</div>
                          </GridItem>
                          <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                            {this.renderRoundQuestionMark("preservePlaylistFilename")}
                          </GridItem>
                          <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                            <Switch
                              disabled={false}
                              checked={preservePlaylistFilename || false}
                              onChange={(e) =>
                                this.modifyCurrentChannel({ preservePlaylistFilename: e.target.checked }, false)
                              }
                              value={preservePlaylistFilename || false}
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}>
                  <div style={{ float: "left", marginTop: "15px" }}>Always generate titleIds from the source URL</div>
                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  {this.renderRoundQuestionMark("generateTitleIdsUsingURL")}
                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <Switch
                    disabled={false}
                    checked={generateTitleIdsUsingURL || false}
                    onChange={(e) => this.modifyCurrentChannel({ generateTitleIdsUsingURL: e.target.checked })}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ "aria-label": "status checkbox" }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {channelType === "live" && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Ad Slate</h5>
                  <React.Fragment>
                    <div>
                      It is possible that the returned ads from the ad-server do not completely fill out the requested
                      ad-pod. (e.g. if you have a 40 second pod for ads but there are only ads of the size of 30 seconds
                      on the ad-server) In this case we will fill the remaining time with a ad-slate that can be defined
                      here. If you do not have an ad-slate in place yet you can choose the Serverside.ai default and
                      change it later.
                    </div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <ResourceEnumDropdownWithStatus
                              style={{ width: "100%" }}
                              classes={classes}
                              handleChange={this.handleAdSlateSelect}
                              value={this.state.currentAdSlateId}
                              data={this.state.adSlates}
                              inputLabelText={"Select Ad-Slate from Assets:"}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <label>or upload your own *.mp4 file:</label>
                            <FileUploadWithChunk
                              url={() => backend.getMediaUploadUrl(this.props.id || id, this.state.fileSize)}
                              mainUrl={() => backend.getMainUrl()}
                              headers={(res) =>
                                backend.combineHeader({
                                  profile,
                                  name: this.state.fileName || "ad slate",
                                  type,
                                  http,
                                  size: this.state.file?.size || undefined,
                                })
                              }
                              onLoad={this.onUploadSuccessful}
                              onError={(text) => {
                                Swal.fire({
                                  type: "warning",
                                  title: "Upload Error",
                                  text,
                                  confirmButtonText: "OK",
                                })
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {this.state.encodingProgress && (
                      <Grid>
                        <h5>Ad slate conditioning</h5>
                        <LinearProgress variant="determinate" value={this.state.encodingProgress} />
                      </Grid>
                    )}
                    <TextField
                      id="adSlateManifestUrl"
                      label="Ad Slate URL"
                      placeholder="Enter Ad Slate URL, if available"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={adSlateManifestUrl}
                      disabled
                    />
                    <div style={{ color: "red" }}>{this.getErrors("adSlateManifestUrl")}</div>
                  </React.Fragment>
                </Grid>
              </CardBody>
            </Card>
          )}
          {isBitmovin && (
            <Card>
              <CardBody>
                <Grid item xs={12}>
                  <h5>Audio normalization</h5>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem>
                          <div>
                            <Switch
                              disabled={false}
                              checked={useAudioNormalization}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.updateDraftValueForProfile(CURRENT_CHANNEL_PROFILE, {
                                    audioNormalization,
                                    useAudioNormalization: true,
                                  })
                                } else {
                                  this.updateDraftValueForProfile(CURRENT_CHANNEL_PROFILE, {
                                    useAudioNormalization: false,
                                  })
                                }
                              }}
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ "aria-label": "status checkbox" }}
                            />{" "}
                            Use audio normalization
                          </div>
                        </GridItem>
                      </GridContainer>
                      {useAudioNormalization && (
                        <>
                          <GridContainer>
                            <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                              <div style={{ float: "left", marginTop: "15px" }}>Integrated Loudness</div>
                            </GridItem>
                            <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                              {this.renderRoundQuestionMark("integratedLoudness")}
                            </GridItem>
                            <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                              <TextField
                                id="audioNormalization.integratedLoudness"
                                name="audioNormalization.integratedLoudness"
                                placeholder="Enter integratedLoudness value"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                fullWidth
                                onChange={(e) =>
                                  this.modifyProfile({
                                    useAudioNormalization,
                                    audioNormalization: { ...audioNormalization, integratedLoudness: e.target.value },
                                  })
                                }
                                value={audioNormalization.integratedLoudness || ""}
                                style={{ maxWidth: 167 }}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                              <div style={{ float: "left", marginTop: "15px" }}>Loudness Range</div>
                            </GridItem>
                            <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                              {this.renderRoundQuestionMark("loudnessRange")}
                            </GridItem>
                            <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                              <TextField
                                id="audioNormalization.loudnessRange"
                                name="audioNormalization.loudnessRange"
                                placeholder="Enter loudnessRange value"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                fullWidth
                                onChange={(e) =>
                                  this.modifyProfile({
                                    useAudioNormalization,
                                    audioNormalization: { ...audioNormalization, loudnessRange: e.target.value },
                                  })
                                }
                                value={audioNormalization.loudnessRange || ""}
                                style={{ maxWidth: 167 }}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}>
                              <div style={{ float: "left", marginTop: "15px" }}>Maximum True Peak Level</div>
                            </GridItem>
                            <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>
                              {this.renderRoundQuestionMark("maximumTruePeakLevel")}
                            </GridItem>
                            <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                              <TextField
                                id="audioNormalization.maximumTruePeakLevel"
                                name="audioNormalization.maximumTruePeakLevel"
                                placeholder="Enter maximumTruePeakLevel value"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                fullWidth
                                onChange={(e) =>
                                  this.modifyProfile({
                                    useAudioNormalization,
                                    audioNormalization: { ...audioNormalization, maximumTruePeakLevel: e.target.value },
                                  })
                                }
                                value={audioNormalization.maximumTruePeakLevel || ""}
                                style={{ maxWidth: 167 }}
                              />
                            </GridItem>
                          </GridContainer>
                        </>
                      )}
                    </GridItem>
                  </GridContainer>
                </Grid>
              </CardBody>
            </Card>
          )}
          <Card>
            <CardBody>
              <Grid item xs={12}>
                {backButton}
                <Button
                  style={{ margin: "20px 10px" }}
                  variant="contained"
                  color="success"
                  onClick={this.handleSave}
                  disabled={disableSaveButton}
                >
                  {saveButtonLabel}
                  <Spinner size={12} color={"#FFF"} radius={2} visible={fetching} />
                </Button>
              </Grid>
            </CardBody>
          </Card>
        </React.Fragment>
      )
    }

    return <GridItem xs={12}>{content}</GridItem>
  }
  onChangeHandler = (event) => {
    this._setState({ fileName: event.target.files[0] ? event.target.files[0].name : "", file: event.target.files[0] })
  }
  formGetter = () => new FormData(ReactDom.findDOMNode(this.uploadForm))
  formRenderer = (onSubmit) => {
    window.boundObject = onSubmit.boundObject
    return (
      <div>
        <form
          onSubmit={async (e) => {
            e.persist()
            e.preventDefault()
            await backend.modelSchema("channels") // to make sure that token is fresh
            onSubmit(e)
          }}
          ref={(x) => (this.uploadForm = x)}
          method={"post"}
        >
          <Button variant={"contained"} component={"label"} color={"white"}>
            Select File
            <input
              name={"file"}
              type={"file"}
              style={{ display: "none" }}
              accept={".mp4,.mov"}
              onChange={this.onChangeHandler.bind(this)}
            />
          </Button>
          <Button
            style={{ float: "right" }}
            disabled={!this.state.fileName}
            type={"submit"}
            color={this.state.fileName ? "dribbble" : "transparent"}
          >
            Upload
          </Button>
        </form>
      </div>
    )
  }
  progressRenderer = (progress, hasError) => {
    let message = ""
    if (hasError) {
      message = "failed to upload."
    } else if (this.state.fileName) {
      if (progress === 100) {
        message = "file uploaded."
      } else if (progress > -1) {
        message = "uploading..."
      }
    }
    return (
      <React.Fragment>
        <div>
          <label>{this.state.fileName || "no file selected"}</label>
          <label style={{ float: "right", color: hasError ? "#a94442" : "" }}>{message}</label>
        </div>
        <div>
          <LinearProgress variant={"determinate"} value={progress} color={hasError ? "secondary" : "primary"} />
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(regularFormsStyle)(ChannelDetailsPage)
